exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"VictorSerif\";\n  src: url(\"/static/css/fonts/VictorSerif-Light.woff2\") format(\"woff2\"), url(\"/static/css/fonts/VictorSerif-Light.woff\") format(\"woff\");\n  font-weight: 100;\n  font-style: normal;\n  font-stretch: normal; }\n\n@font-face {\n  font-family: \"VictorSerifMedium\";\n  src: url(\"/static/css/fonts/VictorSerif-Medium.woff2\") format(\"woff2\"), url(\"/static/css/fonts/VictorSerif-Medium.woff\") format(\"woff\");\n  font-weight: 100;\n  font-style: normal;\n  font-stretch: normal; }\n", "",{"version":3,"sources":["/home/webapp/django-projects/inventure/frontend/src/css/fonts.scss"],"names":[],"mappings":"AAAA;EACI,0BAA0B;EAC1B,qIACkE;EAClE,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB,EAAA;;AAGxB;EACI,gCAAgC;EAChC,uIACmE;EACnE,gBAAgB;EAChB,kBAAkB;EAClB,oBAAoB,EAAA","file":"fonts.scss","sourcesContent":["@font-face {\n    font-family: \"VictorSerif\";\n    src: url(\"/static/css/fonts/VictorSerif-Light.woff2\") format(\"woff2\"),\n        url(\"/static/css/fonts/VictorSerif-Light.woff\") format(\"woff\");\n    font-weight: 100;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n@font-face {\n    font-family: \"VictorSerifMedium\";\n    src: url(\"/static/css/fonts/VictorSerif-Medium.woff2\") format(\"woff2\"),\n        url(\"/static/css/fonts/VictorSerif-Medium.woff\") format(\"woff\");\n    font-weight: 100;\n    font-style: normal;\n    font-stretch: normal;\n}\n\n$small-caps: 13px;\n$body-copy: 12px;\n$font-small: 20px;\n$font-medium: 31px;\n$font-large: 50px;\n\n$line-height-body: 1.3;\n$line-height-small: 1.3;\n$line-height-medium: 1.3;\n$line-height-large: 1.3;\n\n:export {\n    smallCaps: $small-caps;\n    bodyCopy: $body-copy;\n    fontSmall: $font-small;\n    fontMedium: $font-medium;\n    fontLarge: $font-large;\n    lineHeightBody: $line-height-body;\n    lineHeightSmall: $line-height-small;\n    lineHeightMedium: $line-height-medium;\n    lineHeightLarge: $line-height-large;\n}\n"]}]);
// Exports
exports.locals = {
	"smallCaps": "13px",
	"bodyCopy": "12px",
	"fontSmall": "20px",
	"fontMedium": "31px",
	"fontLarge": "50px",
	"lineHeightBody": "1.3",
	"lineHeightSmall": "1.3",
	"lineHeightMedium": "1.3",
	"lineHeightLarge": "1.3"
};