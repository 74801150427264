import styled, { css } from 'styled-components'
import colors from '../../css/colors.scss'
import fonts from '../../css/fonts.scss'
const devices = {
    mobile: '(max-width: 480px)',
    tablet: '(max-width: 900px)',
    desktop: '(max-width: 1240px)',
    large: '(max-width: 2200px)',
    extraLarge: '(max-width: 3000px)',
}

const minDevices = {
    mobile: '(min-width: 480px)',
    tablet: '(min-width: 900px)',
    desktop: '(min-width: 1240px)',
    large: '(min-width: 2200px)',
    extraLarge: '(min-width: 3000px)',
}


const margins = css`
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
    margin-left: ${({ marginleft = 0 }) => marginleft}px;
    margin-right: ${({ marginright = 0 }) => marginright}px;
    @media ${devices.mobile} {
        ${({ margintop }) => margintop && `margin-top: ${margintop / 2}px;`}
        ${({ marginbottom }) => marginbottom && `margin-bottom: ${marginbottom / 2}px;`}
    }
`

const paddings = css`
    padding-top: ${({ paddingtop = 0 }) => paddingtop}px;
    padding-bottom: ${({ paddingbottom = 0 }) => paddingbottom}px;
    @media ${devices.mobile} {
        ${({ paddingtop }) => paddingtop && `padding-top: ${paddingtop / 2}px;`}
        ${({ paddingbottom }) => paddingbottom && `padding-bottom: ${paddingbottom / 2}px;`}
    }
`

const Container = styled.section`
    margin-top: ${props => props.margintop || 0}px;
    margin-bottom: ${props => props.marginbottom || 0}px;
    @media ${minDevices.desktop} {
        float: right;
        width: calc(100% - 600px);
        margin-right: 50px;
    }
    @media ${devices.desktop} {
        margin-top: ${props => Number(props.margintop) + 50 || 50}px;
        margin-left: 20px;
        margin-right: 20px;
    }
    @media ${devices.mobile} {
        padding-top: ${props => props.mobilepaddingtop || 0}px;
        margin-top: ${props => props.margintop || 0}px;
    }
`

const Image = styled.img`
    position: relative;
    display: block;
    vertical-align: middle;
`

const tabletWidthCss = css`
    @media ${devices.tablet} {
        width: ${({ tabletWidth = 100 }) => tabletWidth}%;
    }
`

const GridColumn = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width = 100 }) => width}%;
    ${({ floated }) => floated
        ? floated === 'right'
            ? 'margin-left: auto;'
            : 'margin-rigth: auto;'
        : ''
    }
    ${({ tabletWidth }) => tabletWidth && tabletWidthCss}
    @media ${devices.mobile} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    ${margins}
`

const tabletColumnsCss = css`
    @media ${devices.tablet} {
        width: ${({ columnsTablet = 1 }) => 100 / columnsTablet}%;
    }
`

const columnsCss = css`
    & > ${GridColumn} {
        width: ${({ columns = 1, totalmargin }) => totalmargin ? `calc(${100 / columns}% - ${Math.ceil(totalmargin / columns)}px)` : `${100 / columns - 2}%`};
        ${({ columnsTablet }) => columnsTablet && tabletColumnsCss};
        @media ${devices.mobile} {
            width: 100%;
        }
    }
`

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    ${props => props.justifycontent && `justify-content: ${props.justifycontent};`}
    ${props => props.width ? 'width:' + props.width + ';' : ''}
    ${margins}
    ${({ columns }) => columns && columnsCss}
`

Grid.Column = GridColumn

const FlexGrid = styled.div`
    display: flex;
    justify-content: space-between;
    &:not(:first-of-type) {
        margin-top: ${({ margintop }) => margintop || 30}px;
    }

    @media ${devices.mobile} {
        flex-direction: column;
    }
`

const FlexGridColumn = styled.div`
    border-top: 2px solid ${colors.maroon};
    font-size: ${fonts.fontMedium};
    padding: 15px 0;

    @media only screen and ${devices.mobile} {
        font-size: 35px;
    }

    @media ${minDevices.tablet} {
        width: calc(${props => props.width}% - 15px);
    }
`

export {
    Container,
    Image,
    Grid,
    minDevices,
    devices,
    margins,
    paddings,
    FlexGrid,
    FlexGridColumn,
}
