import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { loadProjectList, loadPage, filterProjects, changeProjectLayout, clearProject } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import { ProjectGrid, ProjectList } from '../'
import { Container, devices, ViewButton } from '../Common'

class Projects extends Component {
    componentDidMount() {
        const { dispatch, page, allProjects, order, categories } = this.props
        const { category, subCategory } = this.props.match.params
        dispatch(clearProject())
        dispatch(filterProjects(allProjects, category || 'featured', subCategory, order, categories))
        if (page.slug !== 'work') dispatch(loadPage('work'))
        if (!allProjects.length) dispatch(loadProjectList(category, subCategory))
    }

    changeView = (layout, columns = 1) => {
        const { dispatch } = this.props
        dispatch(changeProjectLayout({ layout, columns }))
    }

    render() {
        const { page, projects } = this.props
        const { layout, columns } = this.props.view
        const { category, subCategory } = this.props.match.params
        let title = subCategory || category
        if (title) title = title.split('-').map(e => e[0].toUpperCase() + e.slice(1)).join(' ')
        const meta = {
            title: page.meta_title || `Inventure | ${title || 'Work'}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        const isMobile = window.innerWidth < 480

        return (
            <Container margintop={50} mobilepaddingtop={60}>
                <DocumentMeta {...meta} />
                {!isMobile ?
                    <ProjectViewChangeContainer>
                        <ProjectViewChangeOptions>
                            <ProjectView role='button' aria-label='Grid' image={`full${layout === 'full' ? '-active' : ''}`} onClick={() => this.changeView('full')} />
                            <ProjectView role='button' aria-label='Small Grid' image={`tiles${layout === 'grid' ? '-active' : ''}`} onClick={() => this.changeView('grid', 2)} />
                            <ProjectView role='button' aria-label='List' image={`list${layout === 'list' ? '-active' : ''}`} onClick={() => this.changeView('list')} />
                        </ProjectViewChangeOptions>
                    </ProjectViewChangeContainer>
                    : ''}
                <ProjectContainer>
                    {/* TODO:  add loading spinner while getting list of projects */}
                    {layout === 'list' ? (
                        <ProjectList projects={projects} selectedType={title} />
                    ) : (
                            <ProjectGrid projects={projects} columns={columns} />
                        )}
                </ProjectContainer>
            </Container>
        )
    }
}

const ProjectViewChangeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    margin-right: 20px;
    @media ${devices.desktop} {
        margin-right: 0;
    }
`

const ProjectContainer = styled.div`

`

const ProjectViewChangeOptions = styled.div`
    display: flex;

`

const ProjectView = styled(ViewButton)`
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        allProjects: state.project.list,
        projects: state.project.filteredList,
        order: state.project.order,
        categories: state.project.categories,
        view: state.project.view,
        menu: state.content.menu,
    }
}

const connectedProjects = connect(mapStateToProps)(Projects)
export { connectedProjects as Projects }
