import axios from 'axios'

export const Team = {
    getList: () => axios.get(`/api/team-list/`),
    get: (slug) => axios.get(`/api/team/${slug}/`)
}

export const Office = {
    getList: () => axios.get('/api/office/')
}

export const Job = {
    getList: () => axios.get(`/api/job-posting/`),
    get: (slug) => axios.get(`/api/job-posting/${slug}/`)
}

export const Benefit = {
    getList: () => axios.get(`/api/benefits/`)
}

export const GeneralApplication = {
    get: () => axios.get('/api/general-app/0/')
}
