import axios from 'axios'

export const Article = {
    getList: () => axios.get('/api/news/'),
    get: (slug) => axios.get(`/api/news/${slug}/`),
}

export const Brochure = {
    getList: () => axios.get('/api/brochures/'),
}

export const WhitePaper = {
    getList: () => axios.get('/api/white-papers/'),
}

export const Presentation = {
    getList: () => axios.get('/api/presentations/'),
}