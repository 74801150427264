import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadPage, loadArticleList, clearArticle, setCardHeight } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import colors from '../../css/colors.scss'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import {
    Container,
    Header,
    devices,
    FlexGrid,
    FlexGridColumn,
    ImageCard,
} from '../Common'

const ArticleCard = ({ article, imageHeight }) => (
    <Article>
        <ArticleDate>{format(Date.parse(article.date), 'MMMM d, yyyy').toUpperCase()}</ArticleDate>
        <Header marginbottom={article.hero_image ? 30 : 15}>{article.title}</Header>
        {article.hero_image &&
            <ArticleImage
                columns={1}
                background={article.hero_image}
                height={imageHeight}
                role='img'
                aria-label={`A picture of`}
                data-clippath={'alt1'}
            />
        }
        <ArticlePreview dangerouslySetInnerHTML={{ __html: article.short_text }} />
        <ArticleLink to={`/news/${article.slug}`}>Read More &rarr;</ArticleLink>
    </Article>
)

class News extends Component {
    constructor(props) {
        super(props)
        this.pictureRefs = []
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadPage('news'))
        dispatch(clearArticle())
        dispatch(loadArticleList())
        this.updateHeight()
        window.addEventListener('resize', this.updateHeight.bind(this));
    }

    componentDidUpdate() {
        this.updateHeight()
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateHeight)
    }

    setRef = (ref) => {
        this.pictureRefs.push(ref)
    }

    updateHeight = () => {
        if (this.pictureRefs.length) {
            const ratio = 6 / 4, width = this.pictureRefs[0].offsetWidth
            const height = Math.round(width / ratio)
            this.props.dispatch(setCardHeight(height))
        }
    }

    render() {
        const { page, articles, cardHeight } = this.props
        const meta = {
            title: page.meta_title || 'Inventure',
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50}>
                    <Header marginbottom={30}>News</Header>
                    {articles.map(({ year, articles }, i) =>
                        <FlexGrid margintop={'0'} key={i}>
                            <FlexGridColumn width={25}>{year}</FlexGridColumn>
                            <FlexGridColumn width={75} ref={this.setRef}>
                                {articles.map((a, j) => <ArticleCard key={j} imageHeight={cardHeight} article={a} />)}
                            </FlexGridColumn>
                        </FlexGrid>
                    )}
                </Container>
            </React.Fragment>
        )
    }
}

const Article = styled.div`
    padding-bottom: 30px;
    padding-top: 9px;
    &:not(:first-child) {
        padding-top: 30px;
        border-top: 1px solid ${colors.maroon};
    }

    @media ${devices.mobile} {
        font-size: 35px;
    }
`

const ArticleImage = styled(ImageCard)`
    margin-bottom: 30px;
`

const ArticleDate = styled.div`
    font-size: 12px;
    font-family: 'VictorSerifMedium';
    margin-bottom: 5px;
    letter-spacing: 1.5px;
`

const ArticlePreview = styled.div`
    font-size: 21px;
    /* margin: 30px 0; */
    margin-bottom: 30px;
    line-height: 1.3;

    @media ${devices.mobile} {
        font-size: 21px;
    }
`

const ArticleLink = styled(Link)`
    color: ${colors.purple};
    text-decoration: none;
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        dimensions: state.content.dimensions,
        articles: state.news.articleList,
        cardHeight: state.project.cardHeight,
    }
}

const connectedNews = connect(mapStateToProps)(News)
export { connectedNews as News }
