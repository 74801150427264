import React, { Component } from 'react'
import styled from 'styled-components'
import { devices, FlexGrid, FlexGridColumn, ImageCard, } from '../Common'

class NewsBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            block: props.block,
        }
    }

    updateHeight = (cols) => {
        const { image_orientation } = this.state.block
        const { imageHeight } = this.props
        const ratio = image_orientation === 'landscape' ? 6 / 4 : 5 / 7,
            width = imageHeight / cols
        return Math.round(width / ratio);
    }

    render() {
        const { block } = this.state
        const { type, text, text_2, image_side, image } = block
        const mobile = window.innerWidth < 480
        return (
            <React.Fragment>
                {type.includes('TWO_COLUMN_TEXT') ? (
                    <NewsGrid imageSide={image_side}>
                        <NewsColumn className='richtext' dangerouslySetInnerHTML={{ __html: text }} />
                        {type.includes('_IMAGE') ? (
                            <NewsColumn>
                                <ImageCard data-clippath={'alt1'} height={this.updateHeight(mobile ? 1 : 2)} background={image} />
                            </NewsColumn>
                        ) : <NewsColumn className='richtext' dangerouslySetInnerHTML={{ __html: text_2 }} />}
                    </NewsGrid>
                ) : ''}
                {type === 'IMAGE' ? <ImageCard data-clippath={'alt1'} margintop={20} marginbottom={20} height={this.updateHeight(1)} background={image} /> : ''}
                {type === 'TEXT' ? <NewsText className='richtext' dangerouslySetInnerHTML={{ __html: text }} /> : ''}
            </React.Fragment>
        )
    }
}

const NewsGrid = styled(FlexGrid).attrs({ margintop: 20 })`
    flex-direction: ${({ imageSide }) => imageSide === 'LEFT' ? 'row-reverse' : 'row'};
    margin-bottom: 20px;
`

// const NewsImage = styled.div`
//     width: 100%;
//     margin: 20px 0;
//     background: center / ${props => props.orientation || 'cover'} no-repeat url(${props => props.src});
// `

const NewsText = styled.div`
    margin: 20px 0;

    @media ${devices.mobile} {
        font-size: 20px;
    }
`

const NewsColumn = styled(FlexGridColumn).attrs({ width: 47 })`
    border: none;
    font-size: 20px;
    line-height: 1.3;
    padding: 0;
    margin-bottom: 20px;

    @media ${devices.mobile} {
        font-size: 20px;
        flex-direction: ${({ imageSide }) => imageSide === 'LEFT' ? 'column-reverse' : 'column'};
    }
`

export { NewsBlock }
