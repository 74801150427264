import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadPage, loadArticle } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Container, Header, SmallCaps, devices } from '../Common'
import { NewsBlock } from './NewsBlock'
// import fonts from '../../css/fonts.scss'

class NewsDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            height: '',
            width: '',
        }
        this.newsBlock = React.createRef()
    }

    componentDidMount() {
        const { dispatch, match } = this.props
        const { slug } = match.params
        dispatch(loadPage('news'))
        dispatch(loadArticle(slug))
        this.getWidth()
        window.addEventListener('resize', this.getWidth.bind(this));
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.getWidth)
    }

    // componentDidUpdate() {
    //     this.getWidth()
    // }

    getWidth = () => {
        this.setState({ width: Math.round(this.newsBlock.current.offsetWidth) })
    }

    render() {
        const { page, article } = this.props
        const { title, date, article_blocks } = article
        const mobile = window.innerWidth < 480
        const meta = {
            title: `Inventure | ${title}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }
        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={mobile ? 100 : 50} ref={this.newsBlock}>
                    <ArticleLink to={'/news'}>&larr; Back</ArticleLink>
                    <NewsHeader unsetmaxwidth={true} fontSize={50}>{title}</NewsHeader>
                    <ArticleDate>{date && format(Date.parse(date), 'MMMM d, yyyy').toUpperCase()}</ArticleDate>
                    {article_blocks && article_blocks.map((b, i) => <NewsBlock key={i} imageHeight={this.state.width} block={b} />)}
                </Container>
            </React.Fragment>
        )
    }
}

const NewsHeader = styled(Header)`

    @media ${devices.mobile} {
        font-size: 30px !important;
    }
`

const ArticleLink = styled(Link)`
    text-decoration: none;
`

const ArticleDate = styled(SmallCaps)`
    margin-top: 10px;
    margin-bottom: 60px;
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        dimensions: state.content.dimensions,
        article: state.news.article,
    }
}

const connectedNewsDetail = connect(mapStateToProps)(NewsDetail)
export { connectedNewsDetail as NewsDetail }
