import React, { useRef, useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { ImageCard, devices } from '../Common'
import { Link } from 'react-router-dom'

const ProjectCard = ({ project, columns, height }) => (
    <Card width={100 / columns} to={`/work/project/${project.slug}`} marginright={20} columns={columns}>
        <ImageCard
            columns={columns}
            background={project.hero_image}
            height={height}
            role='img'
            aria-label={`A picture of ${project.name}`}
            data-clippath={'alt1'}
        />
        <ProjectTitle>{project.name}</ProjectTitle>
    </Card>
)

const ProjectGrid = ({ projects, columns }) => {
    const [projectState, setProjectState] = useState(projects)
    const [columnState, setColumnState] = useState(columns)
    const [cardHeight, setCardHeight] = useState(600)

    const grid = useRef(null)

    const updateHeight = () => {
        const ratio = 6 / 4,
            width = (grid.current.offsetWidth - 20) / columns
        const height = Math.round(width / ratio)
        setCardHeight(height)
    }

    useLayoutEffect(() => {
        setProjectState(projects)
        setColumnState(columns)
        updateHeight()
        window.addEventListener('resize', updateHeight)

        // return () => {
        //     window.removeEventListener('resize', updateHeight)
        // }
    }, [projects, columns])

    return (
        <GridContainer marginright={20} ref={grid}>
            {projectState.map((e, i) =>
                <ProjectCard
                    key={i}
                    project={e}
                    columns={columnState}
                    height={cardHeight}
                />
            )}
        </GridContainer>
    )
}

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-right: ${({ marginright }) => marginright}px; */

    &::after {
        content: "";
        flex: auto;
    }
`

const Card = styled(Link)`
    flex: 1 0 ${({ width }) => width}%;
    text-decoration: none;
    max-width: calc(${({ width }) => width}% - ${({ marginright }) => marginright}px);
    margin-right: ${({ marginright }) => marginright}px;
    ${({ columns, width, marginright }) => {
        if (columns === 1) {
            return `
                @media ${devices.desktop} {
                    margin-right: 0;
                    max-width: 100%;
                }
            `
        } else {
            return `
                @media ${devices.desktop} {
                    margin-right: 0;
                    max-width: calc(${width}% - ${marginright / 2}px);
                }
            `
        }
    }}

    @media ${devices.mobile} {
        margin-right: 0;
        max-width: none;
    }
`

const ProjectTitle = styled.div`
    margin-top: 15px;
    margin-bottom: 40px;
`

export { ProjectGrid }