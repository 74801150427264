import React, { Component } from 'react'
import styled from 'styled-components'
import {
    devices,
} from '../../Common'
import fonts from '../../../css/fonts.scss'

class Tabs extends Component {
    state = {
        activeTab: 0,
    }

    setActiveTab = (id) => this.setState({ activeTab: id })

    render() {
        const { tabs } = this.props
        const { activeTab } = this.state
        const titles = tabs.map(t => t.header), body = tabs.map(t => t.body)
        return (
            <React.Fragment>
                <TabContainer>
                    {titles.map((title, i) =>
                        <Tab
                            onClick={() => this.setActiveTab(i)}
                            active={i === activeTab}
                            name={title.toLowerCase()}
                            key={i}
                        >
                            {title}
                        </Tab>
                    )}
                </TabContainer>
                <BodyContainer>
                    {body.map((b, i) => <TabBody active={i === activeTab} key={i}>{b}</TabBody>)}
                </BodyContainer>
            </React.Fragment>
        )
    }
}

const TabContainer = styled.div`
    margin-bottom: 50px;
`

const Tab = styled.div`
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
    position: relative;
    background-image: ${props => props.active ? `url(/static/images/${props.name}-tab.svg)` : 'none'};
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 8px 8px;

    @media ${devices.mobile} {
        font-size: 17px;
    }
`

const BodyContainer = styled.div`
    margin-bottom: 10px;
`

const TabBody = styled.div`
    display: ${({ active }) => active ? 'block' : 'none'};
    font-size: ${fonts.fontLarge};

    @media only screen and ${devices.desktop} {
        font-size: 55px;
    }

    @media ${devices.mobile} {
        font-size: ${fonts.fontSmall};
    }
`

export { Tabs }
