exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".clippath-inner {\n  overflow: hidden !important; }\n", "",{"version":3,"sources":["/home/webapp/django-projects/inventure/frontend/src/css/colors.scss"],"names":[],"mappings":"AAiBA;EACI,2BAA2B,EAAA","file":"colors.scss","sourcesContent":["$black: #000;\n$maroon: #6B0049;\n$purple: #A899E2;\n$light-blue: #A5E5FF;\n$white: #FBF9F1;\n$yellow: #F1C300;\n\n// Export for use in React components\n:export {\n    black: $black;\n    white: $white;\n    maroon: $maroon;\n    purple: $purple;\n    lightBlue: $light-blue;\n    yellow: $yellow;\n}\n\n.clippath-inner {\n    overflow: hidden !important;\n}\n"]}]);
// Exports
exports.locals = {
	"black": "#000",
	"white": "#FBF9F1",
	"maroon": "#6B0049",
	"purple": "#A899E2",
	"lightBlue": "#A5E5FF",
	"yellow": "#F1C300"
};