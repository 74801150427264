import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadPage, loadBrochures, loadPresentations, loadWhitePapers } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import { Container, Separator, Header, UpperHeader, UnstyledLink, } from '../Common'
import styled from 'styled-components'

const InsightSection = ({ title, list }) => (
    <InsightContainer>
        <InsightHeader marginbottom={15}>{title.toUpperCase()}</InsightHeader>
        {list.map((item, i) =>
            <UnstyledLink
                key={i}
                as={'a'}
                href={item.link || item.linked_file}
                target='_blank'
                rel='noopener noreferrer'
                fontSize={30}
                marginbottom={5}
            >
                {item.name}
            </UnstyledLink>
        )}
    </InsightContainer>
)

class Insights extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadPage('insights'))
        dispatch(loadBrochures())
        dispatch(loadPresentations())
        dispatch(loadWhitePapers())
    }


    render() {
        const { page, brochures, whitePapers, presentations } = this.props
        const meta = {
            title: `Inventure | ${page.meta_title || page.title}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50} marginbottom={50}>
                    <Header marginbottom={60} fontSize={48}>{page.title}</Header>

                    <InsightSection title={'Brochures'} list={brochures} />
                    <Separator />
                    <InsightSection title={'White Papers'} list={whitePapers} />
                    <Separator />
                    <InsightSection title={'Presentations'} list={presentations} />
                </Container>
            </React.Fragment>
        )
    }
}

const InsightContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const InsightHeader = styled(UpperHeader)`
    font-family: 'VictorSerifMedium';
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        brochures: state.news.brochures,
        whitePapers: state.news.whitePapers,
        presentations: state.news.presentations,
    }
}

const connectedInsights = connect(mapStateToProps)(Insights)
export { connectedInsights as Insights }