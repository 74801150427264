import { teamTypes } from '../_constants'

const initialState = {
    list: [],
    page: {},
    filteredList: [],
    benefits: [],
    view: {
        layout: 'grid',
        columns: 2,
    },
    officeList: [],
    jobList: [],
    generalApplication: {},
}

export function team(state = initialState, action) {
    switch (action.type) {
        case teamTypes.TEAM_LIST_SUCCESS:
            return {
                ...state,
                list: action.list,
                filteredList: action.filteredList,
            }
        case teamTypes.TEAM_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload,
            }
        case teamTypes.OFFICE_LIST_SUCCESS:
            return {
                ...state,
                officeList: action.payload,
            }
        case teamTypes.JOB_LIST_SUCCESS:
            return {
                ...state,
                jobList: action.payload,
            }
        case teamTypes.JOB_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload,
            }
        case teamTypes.BENEFIT_LIST_SUCCESS:
            return {
                ...state,
                benefits: action.payload,
            }
        case teamTypes.FILTER_PEOPLE:
            return {
                ...state,
                filteredList: action.payload,
            }
        case teamTypes.CHANGE_PEOPLE_LAYOUT:
            return {
                ...state,
                view: action.payload,
            }
        case teamTypes.CLEAR_PERSON:
            return {
                ...state,
                page: {},
            }
        case teamTypes.GENERAL_APP_SUCCESS:
            return {
                ...state,
                generalApplication: action.payload,
            }
        default:
            return state
    }
}
