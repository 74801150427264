import React, { Component } from 'react'
import styled from 'styled-components'
import { SimpleCarousel } from '../../'
import { Modal, devices } from '../../Common'
import colors from '../../../css/colors.scss'

class LightBoxModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            slides: props.slides || [],
            showLightBoxModal: props.showLightBoxModal,
            activeImage: props.activeImage,
            closeModal: props.closeModal,
            height: 0,
            width: 0,
            horizontalPadding: 100,
            verticalPadding: 40,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            slides: nextProps.slides,
            showLightBoxModal: nextProps.showLightBoxModal,
            closeModal: nextProps.closeModal,
            activeImage: nextProps.activeImage,
        })
    }

    componentDidMount() {
        this.updateHeight()
        window.addEventListener('resize', this.updateHeight);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateHeight)
    }

    getActiveIndex = () => {
        for (let i = 0; i < this.state.slides.length; i++) {
            if (this.state.slides[i].image === this.state.activeImage) {
                return i
            }
        }
    }

    updateHeight = () => {
        const ratio = 690 / 1120
        let height = window.innerHeight - (this.state.verticalPadding * 2)
        let width = height / ratio
        if (window.innerWidth - (this.state.horizontalPadding * 2) < width) {
            height = (window.innerWidth - (this.state.horizontalPadding * 2)) * ratio
            width = window.innerWidth - (this.state.horizontalPadding * 2)
        }
        this.setState({ height, width })
    }


    render() {
        const { slides, showLightBoxModal, height, width, closeModal, horizontalPadding, verticalPadding } = this.state
        return (
            <LBModal
                background={colors.maroon}
                show={showLightBoxModal}
                horizontalpadding={horizontalPadding}
                verticalpadding={verticalPadding}
            >
                <CarouselContainer height={height} width={width}>
                    <SimpleCarousel
                        slides={slides}
                        lightbox={true}
                        verticalPadding={verticalPadding * 2}
                        horizontalPadding={horizontalPadding * 2}
                        activeSlide={this.getActiveIndex()}
                        updateHeight={this.updateHeight}
                        height={height}
                        width={width}
                    />
                </CarouselContainer>
                <CloseModal onClick={closeModal} role='button' aria-label='Close Modal' />
            </LBModal>
        )
    }
}

const CloseModal = styled.div`
    position: absolute;
    top: 35px;
    right: 50px;
    height: 20px;
    width: 20px;
    background: center / contain no-repeat url(/static/images/white-close.svg);
    cursor: pointer;
`

const CarouselContainer = styled.div`
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    @media ${devices.mobile} {
        width: 100%;
    }
`

const LBModal = styled(Modal)`
    height: calc(100vh - ${({ verticalpadding }) => verticalpadding * 2}px);
    width: calc(100vw - ${({ horizontalpadding }) => horizontalpadding * 2}px);
    padding: ${({ verticalpadding }) => verticalpadding}px ${({ horizontalpadding }) => horizontalpadding}px;
    @media ${devices.mobile} {
        width: 100%;
        height: 100%;
        padding: 0;
    }
    display: flex;
    justify-content: center;
    align-items: center;
`

export { LightBoxModal }
