import { newsTypes } from '../_constants'

const initialState = {
    articleList: [],
    article: {},
    whitePapers: [],
    presentations: [],
    brochures: [],
}

export function news(state = initialState, action) {
    switch (action.type) {
        case newsTypes.ARTICLE_LIST_SUCCESS:
            return {
                ...state,
                articleList: action.payload,
            }
        case newsTypes.ARTICLE_DETAIL_SUCCESS:
            return {
                ...state,
                article: action.payload,
            }
        case newsTypes.WP_SUCCESS:
            return {
                ...state,
                whitePapers: action.payload,
            }
        case newsTypes.PRESENTATION_SUCCESS:
            return {
                ...state,
                presentations: action.payload,
            }
        case newsTypes.BROCHURE_SUCCESS:
            return {
                ...state,
                brochures: action.payload,
            }
        case newsTypes.CLEAR_ARTICLE:
            return {
                ...state,
                article: {},
            }
        default:
            return state
    }
}