import { projectTypes } from '../_constants'

const initialState = {
    list: [],
    page: {},
    filteredList: [],
    view: {
        layout: 'full',
        columns: 1,
    },
    cardHeight: '300',
    order: {},
    categories: {},
}

export function project(state = initialState, action) {
    switch (action.type) {
        case projectTypes.PROJECT_LIST_SUCCESS:
            return {
                ...state,
                list: action.list,
                categories: action.categories,
                order: action.order,
                filteredList: action.filteredList,
            }
        case projectTypes.PROJECT_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload,
            }
        case projectTypes.CLEAR_PROJECT:
            return {
                ...state,
                page: {},
            }
        case projectTypes.FILTER_PROJECTS:
            return {
                ...state,
                filteredList: action.payload,
            }
        case projectTypes.CHANGE_PROJECT_LAYOUT:
            return {
                ...state,
                view: action.payload,
            }
        case projectTypes.SET_CARD_HEIGHT:
            return {
                ...state,
                cardHeight: action.payload,
            }
        default:
            return state
    }
}
