import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../css/colors.scss'

const ListItem = ({ project, last, headerWidths }) => (
    <Item to={ `/work/project/${project.slug}` } margin={ last ? 50 : 0 }>
        <Column borderWidth={ last ? 0 : 1 } width={ headerWidths.header1 }>
            { project.name }
            <TypeContainer>
                { project.type ? project.type.map((e, i) => i === 0 ? e.name : `, ${e.name}`) : '' }
            </TypeContainer>
        </Column>
        <Column borderWidth={ last ? 0 : 1 } width={ headerWidths.header2 }>{ project.year }</Column>
    </Item>
)

class ProjectList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: props.projects,
            selectedType: props.selectedType,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            projects: nextProps.projects,
            selectedType: nextProps.selectedType,
        })
    }

    render() {
        const headerWidths = {
            header1: 67,
            header2: 33,
        }
        return (
            <React.Fragment>
                <ListHeader>
                    <Header borderWidth='2' width={ headerWidths.header1 }>Name</Header>
                    <Header borderWidth='2' width={ headerWidths.header2 }>Year</Header>
                </ListHeader>
                <ListContainer>
                    {this.state.projects ? this.state.projects.map((e, i) =>
                        <ListItem
                            key={ i }
                            last={ i === this.state.projects.length - 1 }
                            project={ e }
                            headerWidths={ headerWidths }
                            selectedType={ this.state.selectedType }
                        />
                    ) : ''}
                </ListContainer>
            </React.Fragment>
        )
    }
}

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;

`

const Header = styled.div`
    width: calc(${props => props.width}% - 15px);
    border-bottom: ${props => props.borderWidth}px solid ${colors.maroon};
    padding: 15px 0;
`

const ListContainer = styled.div`

`

const Item = styled(Link)`
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.margin}px;
`

const Column = styled(Header)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const TypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 20px;
    margin-top: 5px;
`

export { ProjectList }
