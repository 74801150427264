import { createConstants } from '../_helpers/utils'

export const formTypes = createConstants(
    'POST_CONTACT_FORM_REQUEST',
    'POST_CONTACT_FORM_SUCCESS',
    'POST_CONTACT_FORM_FAILURE',
    'POST_GENERAL_APPLICATION_REQUEST',
    'POST_GENERAL_APPLICATION_SUCCESS',
    'POST_GENERAL_APPLICATION_FAILURE',
    'RESET',
)