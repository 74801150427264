import React, { Component } from 'react'
// import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
    Container,
    Grid,
    Image,
    // UnstyledLink,
    Header,
    devices,
    minDevices,
} from '../Common'
import { SocialIcons } from './SocialIcons'
import colors from '../../css/colors.scss'

const FooterLink = ({ title, path }) => (
    <FooterLinkItem as={Link} to={path}>{title}</FooterLinkItem>
)

class Footer extends Component {
    render() {
        return (
            <FooterWrap>
                <FooterContainer>
                    <FooterGrid>
                        <Grid.Column>
                            <FooterHeader>Work</FooterHeader>

                            <FooterLink title={'Workplace'} path={'/work/workplace/'} />
                            <FooterLink title={'Health + Science'} path={'/work/health-science/'} />
                            <FooterLink title={'Architecture + Design'} path={'/work/architecture-design/'} />
                            <FooterLink title={'Experiential Design'} path={'/work/experiential-design/'} />
                        </Grid.Column>

                        <Grid.Column>
                            <FooterHeader>Studio</FooterHeader>

                            <FooterLink title={'About'} path={'/studio/'} />
                            <FooterLink title={'Team'} path={'/team/'} />
                            <FooterLink title={'Careers'} path={'/team/careers/'} />
                            <FooterLink title={'News'} path={'/news/'} />
                            <FooterLink title={'Insights'} path={'/insights/'} />
                            <FooterLink title={'Contact'} path={'/studio/contact/'} />
                        </Grid.Column>

                        <Grid.Column>
                            {/* <FooterHeader>Privacy</FooterHeader>
                            <FooterLink title={'Copyright'} path={'/copyright/'} />
                            <FooterLink title={'Terms of Use'} path={'/terms-of-use/'} /> */}

                            <SocialWrap>
                                <SocialIcons page={'contact'} height={25} margin={15} />
                            </SocialWrap>
                        </Grid.Column>
                    </FooterGrid>
                </FooterContainer>
            </FooterWrap>
        )
    }
}

const SocialWrap = styled.div`
    margin: 10px 0;
    height: 18px;
`

const FooterContainer = styled(Container)`
    margin-bottom: 25px;
    padding-top: 15px;
    border-top: 1px solid maroon;

    @media ${minDevices.desktop} {
        margin-left: auto;
        margin-right: 50px;
        width: calc(100% - 600px);
    }

    @media ${devices.mobile} {
        padding-top: 15px;
    }
`

const FooterHeader = styled(Header).attrs({ fontSize: 24 })`
    color: ${colors.purple};
    font-weight: 400 !important;
    padding-bottom: 10px;

    @media ${devices.mobile} {
        font-size: 17px !important;
    }
`

const FooterWrap = styled.footer`
    width:100%;
    * {
        line-height: 19px;
    }
    ${Container} {
        @media only screen and ${devices.desktop} {
            margin-right: 20px;
        }
    }
`

const FooterLinkItem = styled.div`
    padding-bottom: 10px;
    text-decoration: none;
    display: block;
    font-size: 20px;
    @media ${devices.mobile} {
        padding-bottom: 5px;
        font-size: 17px;
    }
`

const FooterGrid = styled(Grid)`
    flex-wrap: nowrap;
    @media ${devices.mobile} {
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;

        ${Grid.Column} {
            width: 48%;
        }

        ${SocialWrap} {
            display: flex;
            flex-direction: row;
            margin-bottom: 35px;
            ${Image} {
                height: 16px;
                margin-bottom: 23px;
            }
        }
    }
`

// function mapStateToProps(state) {
//     return {

//     }
// }
// const connectedFooter = connect(mapStateToProps)(Footer)
export { Footer }
