import { createConstants } from '../_helpers/utils'

export const newsTypes = createConstants(
    'ARTICLE_LIST_REQUEST',
    'ARTICLE_LIST_SUCCESS',
    'ARTICLE_DETAIL_REQUEST',
    'ARTICLE_DETAIL_SUCCESS',
    'WP_REQUEST',
    'WP_SUCCESS',
    'PRESENTATION_REQUEST',
    'PRESENTATION_SUCCESS',
    'BROCHURE_REQUEST',
    'BROCHURE_SUCCESS',
    'CLEAR_ARTICLE',
)