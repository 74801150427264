import React, { Component } from 'react'
import styled from 'styled-components'
import {
    SimpleBlock,
    SimpleCarousel,
    Table,
    Tabs,
    SlideItems,
} from '../'
import {
    ItalicLink,
    Block,
    Grid,
} from '../Common'

class PageBlock extends Component {

    constructor(props) {
        super(props)
        this.columnRefs = []
        this.state = {
            iframeHeight: 50,
            iframeLoaded: false,
        }
    }

    setImageHeight = (orientation, columns) => {
        const mobile = window.innerWidth < 480
        const ratio = orientation === 'landscape' ? 6 / 4 : 5 / 7
        const width = this.props.width / (mobile ? 1 : columns)
        return Math.round(width / ratio)
    }

    loadIframe = () => {
        if (this.state.iframeLoaded) {
            this.setState({ iframeHeight: 900 })
        }
        this.setState({ iframeLoaded: true })
    }

    render() {
        const { pageBlock, dimensions } = this.props
        return (
            <DynamicWidthBlock marginbottom={0}>
                {
                    pageBlock.hash_url ? <div id={pageBlock.hash_url.toLowerCase().replace(/ /g, '_')} /> : ''
                }
                {
                    pageBlock.block_format === 'CAROUSEL' ? <SimpleCarousel slides={pageBlock.carousel} dimensions={dimensions} content={pageBlock.content} smallTitle={pageBlock.small_title} /> : ''
                }
                {
                    pageBlock.block_format === 'TABLE' ? <Table subBlocks={pageBlock.table} /> : ''
                }
                {
                    pageBlock.block_format === 'TABS' ? <Tabs tabs={pageBlock.tabs} /> : ''
                }
                {pageBlock.block_format === '1-COLUMN' ? (
                    <SimpleBlock
                        pageBlock={pageBlock}
                        iframeHeight={this.state.iframeHeight}
                        loadIframe={this.loadIframe}
                        imageHeight={this.setImageHeight(pageBlock.image_orientation, 1)}
                        columns={1}
                    />
                ) : ''}
                {pageBlock.block_format === '2-COLUMN' ? (
                    <Grid columns={2} totalmargin={15}>
                        {['', '2'].map(id => (
                            <SimpleBlock
                                key={`#${id}`}
                                id={id}
                                pageBlock={pageBlock}
                                imageHeight={this.setImageHeight(pageBlock[`image${id}_orientation`], 2)}
                                textPadding={'10%'}
                                LinkComp={ItalicLink}
                                columns={2}
                            />
                        ))}
                    </Grid>
                ) : ''}
                {pageBlock.block_format === 'SLIDEDOWN' ?
                    <Grid columns={2} justifycontent={'space-between'}>
                        <Grid.Column>
                            <SlideItems slideDowns={pageBlock.slide_downs} subTitle={pageBlock.sub_title} />
                        </Grid.Column>
                        <Grid.Column>
                            <SimpleBlock
                                pageBlock={pageBlock}
                                imageHeight={this.setImageHeight(pageBlock.image_orientation, 2)}
                                columns={1}
                            />
                        </Grid.Column>
                    </Grid>
                    : ''}
            </DynamicWidthBlock>
        )
    }
}

const DynamicWidthBlock = styled(Block)`
    max-width: ${props => props.maxwidth};
`

export { PageBlock }
