import { combineReducers } from 'redux'

import { content } from './content.reducers'
import { team } from './team.reducers'
import { project } from './project.reducers'
import { news } from './news.reducers'
import { form as customForm } from './form.reducers'
import { reducer as form } from 'redux-form'


const rootReducer = combineReducers({
    content,
    team,
    project,
    news,
    form,
    customForm,
})

export default rootReducer
