import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { loadProjectPage } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import { Container, devices } from '../Common'
import { ProjectBlock } from '../'
import { LightBoxModal } from '../Content'
import colors from '../../css/colors.scss'
import fonts from '../../css/fonts.scss'
import scrollToComponent from 'react-scroll-to-component'

class ProjectDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showLightBoxModal: false,
            activeImage: null,
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadProjectPage(this.props.match.params.slug))
    }

    openModal = (activeImage = null) => this.setState({ showLightBoxModal: true, activeImage })

    closeModal = () => this.setState({ showLightBoxModal: false })

    getLightBox = () => {
        const { project } = this.props
        if (!project || !project.blocks) return []

        const lightBox = [{
            image: project.hero_image,
            orientation: 'landscape',
        }]

        project.blocks.forEach(e => {
            if (e.type === 'TWO_PICTURES_TEXT_BOTTOM_RIGHT') {
                lightBox.push({
                    image: e.large_image,
                    orientation: e.large_image_orientation
                }, {
                    image: e.small_image,
                    orientation: e.small_image_orientation
                })
            } else if (e.type === 'TWO_PICTURES_TEXT_TOP_LEFT') {
                lightBox.push({
                    image: e.small_image,
                    orientation: e.small_image_orientation
                }, {
                    image: e.large_image,
                    orientation: e.large_image_orientation
                })
            } else if (e.type === 'CAROUSEL') {
                e.carousel.forEach(el => {
                    if (el.image) {
                        lightBox.push({
                            image: el.image,
                            orientation: el.image_orientation
                        })
                    }
                })
            } else if (e.type === 'ONE_PICTURE') {
                lightBox.push({
                    image: e.large_image,
                    orientation: e.large_image_orientation
                })
            }
        })
        return lightBox
    }

    render() {
        const { project, dimensions } = this.props
        const meta = {
            title: `Inventure | ${project.name}`,
            description: project.body,
        }

        const mobile = window.innerWidth < 480

        const scrollDefaults = {
            offset: 200,
            ease: 'out-cube',
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <HeroImage onClick={() => !mobile ? this.openModal(project.hero_image) : ''} image={project.hero_image}>
                    <HeroImageOverlay data-clippath={'alt1'} />
                    {mobile && <DownArrow onClick={() => scrollToComponent(this.info, scrollDefaults)} />}
                </HeroImage>
                <Container offset={550}>
                    <Info ref={(container) => this.info = container}>
                        <Title>
                            {project.name}
                            {project.linked_file ? <DownloadFile href={project.linked_file}></DownloadFile> : ''}
                        </Title>
                        <Stats>
                            <Row>
                                <Item first={true}>Location</Item>
                                <Item first={true}>{project.location ? project.location.name : ''}</Item>
                            </Row>
                            <Row>
                                <Item>Year</Item>
                                <Item>{project.year}</Item>
                            </Row>
                            <Row>
                                <Item>Type</Item>
                                <TypeContainer>
                                    {project.type ? project.type.map(e => <Item key={e.name}>{e.name}</Item>) : ''}
                                </TypeContainer>
                            </Row>
                            {project.size &&
                                <Row>
                                    <Item>Size</Item>
                                    <Item>{project.size}ft²</Item>
                                </Row>
                            }
                        </Stats>
                    </Info>
                    <Content>
                        <Intro dangerouslySetInnerHTML={{ __html: project.body }} />
                        {project.blocks ? project.blocks.map((e, i) =>
                            <ProjectBlock
                                key={i}
                                block={e}
                                openModal={!mobile ? this.openModal : ''}
                                dimensions={dimensions}
                            />
                        ) : ''}
                    </Content>
                </Container>
                {this.state.showLightBoxModal ? ( // do not mount component unless active, 'dislpay: none' does not prevent the scroll effect
                    <LightBoxModal
                        slides={this.getLightBox()}
                        showLightBoxModal={this.state.showLightBoxModal}
                        closeModal={this.closeModal}
                        activeImage={this.state.activeImage}
                    />
                ) : ''}
            </React.Fragment>
        )
    }
}

const HeroImage = styled.div`
    width: 100%;
    height: 100%;
    background: center / cover no-repeat url(${props => props.image});
    cursor: pointer;

    @media ${devices.mobile} {
        pointer-events: none;
    }
`

const HeroImageOverlay = styled.div`
    /* height: ${window.innerHeight + 100}px; */
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
`

const DownArrow = styled.div`
    background: center / contain no-repeat url('/static/images/white-arrow.svg');
    width: 37px;
    height: 27px;
    transform: rotate(90deg);

    position: absolute;
    bottom: 30px;
    left: 45%;

    @media ${devices.mobile} {
        pointer-events: auto;
    }
`

const Info = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 80px 0;

    @media ${devices.mobile} {
        flex-direction: column;
        margin-top: 40px;
    }
`

const Title = styled.div`
    width: calc(50% - 10px);
    font-size: ${fonts.fontMedium};

    @media ${devices.mobile} {
        width: 100%;
        font-size: ${fonts.fontSmall};
        margin-bottom: 35px;
    }
`

const Stats = styled.div`
    width: calc(50% - 10px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media ${devices.mobile} {
        width: 100%;
        font-size: 17px;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    div:first-of-type {
        margin-right: 15px;
    }
`

const Item = styled.div`
    width: calc(50% - 10px);
    ${props => props.first ? `border-top: 2px solid ${colors.maroon};` : ''}
    border-bottom: 1px solid ${colors.maroon};
    padding: 10px 0;
`

const DownloadFile = styled.div`

`

const TypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(50% - 10px);

    ${Item} {
        width: 100%;
    }
`

const Content = styled.div`
    padding-bottom: 50px;
`

const Intro = styled.div`
    div[class="richtext-small"] {
        font-size: ${fonts.smallCaps};
        font-family: "VictorSerifMedium";
        text-transform: uppercase;
        margin-bottom: 5px;
        letter-spacing: 1.5px;
    }
    margin: 80px 0;
`

function mapStateToProps(state) {
    return {
        project: state.project.page,
        dimensions: state.content.dimensions,
    }
}

const connectedProjectDetail = connect(mapStateToProps)(ProjectDetail)
export { connectedProjectDetail as ProjectDetail }
