import React, { Component } from 'react'
import styled from 'styled-components'
import { devices, minDevices, SmallCaps } from '../Common'

const ImageLarge = ({ image, openModal }) => (
    <LargeImage image={image} data-clippath={'alt1'} onClick={() => openModal(image)} />
)

const TwoPictures = ({ block, type, dimensions, openModal }) => (
    <PictureBlock>
        {/* TODO: arange with flex box to prevent duplicates */}
        {type === 'right' ? <ImageLarge image={block.large_image} dimensions={dimensions} openModal={openModal} /> : ''}
        <Column reverse={type === 'left' ? 0 : 1}>

            <InfoText>
                {block.heading && <SmallCaps marginbottom={10}>{block.heading}</SmallCaps>}
                <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: block.body }} />
            </InfoText>
            <SmallImage image={block.small_image} data-clippath={'alt1'} onClick={() => openModal(block.small_image)} />
        </Column>
        {type === 'left' ? <ImageLarge image={block.large_image} dimensions={dimensions} openModal={openModal} /> : ''}
    </PictureBlock>
)

const Text = ({ block }) => (
    <TextBlock dangerouslySetInnerHTML={{ __html: block.body }} />
)

class ProjectBlock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            block: props.block,
            dimensions: props.dimensions,
            openModal: props.openModal,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            block: nextProps.block,
            dimensions: nextProps.dimensions,
            openModal: nextProps.openModal,
        })
    }

    render() {
        const { block, dimensions } = this.state
        return (
            <React.Fragment>
                {block.type === 'TWO_PICTURES_TEXT_TOP_LEFT' ? (
                    <TwoPictures type='left' block={block} dimensions={dimensions} openModal={this.state.openModal} />
                ) : ''}
                {block.type === 'TWO_PICTURES_TEXT_BOTTOM_RIGHT' ? (
                    <TwoPictures type='right' block={block} dimensions={dimensions} openModal={this.state.openModal} />
                ) : ''}
                {/* {block.type === 'CAROUSEL' ? (
                    <CarouselContainer>
                        <SimpleCarousel showIndicator={false} slides={block.carousel} dimensions={dimensions} />
                    </CarouselContainer>
                ) : ''} */}
                {block.type === 'ONE_PICTURE' ? (
                    <CarouselContainer>
                        <ImageLarge image={block.large_image} dimensions={dimensions} openModal={this.state.openModal} />
                    </CarouselContainer>
                ) : ''}
                {block.type === 'TEXT' ? (
                    <Text block={block} />
                ) : ''}
            </React.Fragment>
        )
    }
}

const TextBlock = styled.article`
    margin-bottom: 35px;

    @media only screen and ${devices.desktop} {
        margin-bottom: 20px; // this has to be large enough for the menu, the color scroll cannot have three different colors
    }
`

const PictureBlock = styled.div`
    display: flex;
    margin-bottom: 35px;
    justify-content: space-between;
    div {
        width: calc(50% - 10px);
        height: 726px;
    }

    @media ${devices.mobile} {
        flex-direction: column;
        div {
            width: 100%;
            height: auto;
        }
    }

    @media only screen and ${devices.desktop} {
        margin-bottom: 20px; // this has to be large enough for the menu, the color scroll cannot have three different colors
    }
`

const SmallImage = styled.div`
    width: 100% !important;
    height: 378px !important;
    background: center / cover no-repeat url(${props => props.image});
    cursor: pointer;

    @media ${devices.mobile} {
        pointer-events: none;
    }
`

const LargeImage = styled.div`
    height: 726px;
    background: center / cover no-repeat url(${props => props.image});
    cursor: pointer;

    @media ${devices.mobile} {
        pointer-events: none;
    }
`

const InfoText = styled.article`
    @media ${minDevices.tablet} {
        height: 100%;
    }
`

const CarouselContainer = styled.div`
    margin-bottom: 20px;

    @media only screen and ${devices.desktop} {
        margin-bottom: 20px; // this has to be large enough for the menu, the color scroll cannot have three different colors
    }
`

const Column = styled.div`
    display: flex;
    flex-direction: column${props => props.reverse ? '-reverse' : ''};
    justify-content: space-evenly;

    @media ${minDevices.desktop} {
        div, article {
            min-height: calc(50% - 10px);
        }
    }
    ${props => props.reverse ?
        `article {
            margin-top: 20px;
        }` :
        `div {
            margin-top: 20px;
        }`
    }
`

export { ProjectBlock }
