import React from 'react'
import styled from 'styled-components'
import { Image } from '../Common'

const socialLinks = [
    {
        name: 'instagram',
        link: 'https://www.instagram.com/inventuredesign'
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/inventuredesign/'
    },
    {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/inventure-design'
    },
    {
        name: 'pinterest',
        link: 'https://www.pinterest.com/inventuredesign'
    },
]

export const SocialIcons = ({ page, height, margin }) => {
    return socialLinks.map(({ name, link }) =>
        <SocialLink key={name} marginright={margin}>
            <a href={`${link}`} target="_blank" rel="noopener noreferrer">
                <Image
                    role='img'
                    alt={`Inventure ${name}`}
                    src={`/static/images/${name}-${page}.svg`}
                    height={height}
                />
            </a>
        </SocialLink>
    )

}

const SocialLink = styled.div`
    float: left;
    margin-right: ${({ marginright }) => marginright}px;
`