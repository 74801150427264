import React, { Component } from 'react'
import styled from 'styled-components'
import { loadJobPage } from '../../_actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import colors from '../../css/colors.scss'
import {
    Header,
    Grid,
    devices,
    Container,
    Image,
    BackButton,
    Button,
} from '../Common'
import { format } from 'date-fns'
import fonts from '../../css/fonts.scss'


class JobDetail extends Component {
    componentDidMount() {
        const { match, dispatch } = this.props
        dispatch(loadJobPage(match.params.position))
    }

    render() {
        const { title, intro, what_you_will_do, what_you_will_bring, deadline, external_application_link } = this.props.job
        const headerMargin = 30
        return (
            <React.Fragment>
                <Container margintop={50}>
                    <BackButton as={Link} to={'/team/careers'}>
                        Back
                        <Image src={'/static/images/back-icon.svg'} height={25} />
                    </BackButton>
                    <JobOverview>{title} &mdash; {intro}</JobOverview>
                    <JobDetails columns={2} marginbottom={50}>
                        <Grid.Column>
                            <Header marginbottom={headerMargin}>What You&apos;ll Do</Header>
                            <ColumnText dangerouslySetInnerHTML={{ __html: what_you_will_do }} />
                        </Grid.Column>
                        <Grid.Column>
                            <Header marginbottom={headerMargin}>What You&apos;ll Bring</Header>
                            <ColumnText dangerouslySetInnerHTML={{ __html: what_you_will_bring }} />
                        </Grid.Column>
                    </JobDetails>

                    <ColumnText>Application deadline: {deadline && format(Date.parse(deadline), 'MMMM d, yyyy')}</ColumnText>
                    {external_application_link && <ApplyButton as={'a'} href={external_application_link} target='_blank'>Apply</ApplyButton>}
                </Container>
            </React.Fragment>
        )
    }
}

const ApplyButton = styled(Button)`
    width: 79px;
    height: 27px;
`

const JobOverview = styled.div`
    font-size: ${fonts.fontMedium};
    margin-bottom: 50px;

    @media ${devices.mobile} {
        font-size: ${fonts.fontSmall};
    }
`

const ColumnText = styled.div`
    font-size: ${fonts.fontSmall};
    @media ${devices.mobile} {
        font-size: 17px;
        margin-bottom: 50px;
    }
`

const JobDetails = styled(Grid)`
    border-top: 1px solid ${colors.maroon};
    padding-top: 50px;
    justify-content: space-between;
`

function mapStateToProps(state) {
    return {
        job: state.team.page,
    }
}

const connectedJobDetail = connect(mapStateToProps)(JobDetail)
export { connectedJobDetail as JobDetail }
