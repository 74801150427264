import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { history } from './_helpers'
import {
    Awards,
    Careers,
    Contact,
    Footer,
    Home,
    PageView,
    People,
    PersonDetail,
    ProjectDetail,
    Projects,
    SideNav,
    StudioOverview,
    JobDetail,
    Flatpage,
    News,
    Insights,
    NewsDetail,
    GeneralApplication,
} from './Components'
import './css/normalize.css'
import './css/App.scss'
import lottie from 'lottie-web'
import styled from 'styled-components'
import ReactGA from 'react-ga'
import Modal from 'react-modal'
import { errorServices } from './_services'
import { resize, loadMenu } from './_actions'

// ReactGA.initialize('UA-39271605-1', { debug: false })
Modal.setAppElement('#root')


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    componentDidCatch(error, info) {
        if (process.env.NODE_ENV === 'production') {
            errorServices.sendError(
                error.message,
                info.componentStack,
                window.location.href,
            )
        }
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) return <h1>Something went wrong.</h1>
        return this.props.children
    }
}

const PageRoute = ({ component: Component, path, exact, ...props }) => (
    <Route
        exact={exact}
        path={path}
        render={() => <Component {...props} key={props.location.key} match={props.match || props.computedMatch} />}
    />
)

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            GAStarted: false
        }
    }

    componentDidMount() {
        const { menu, dispatch } = this.props
        if (!menu.length) dispatch(loadMenu())
        this.sendGA(window.location.pathname)
        history.listen((location) => {
            lottie.setLocationHref(window.location.pathname)
            window.scrollTo(0, 0)
            this.sendGA(location.pathname)
        });
        window.addEventListener('resize', this.resize)
    }

    resize = () => {
        const { dispatch } = this.props
        dispatch(resize({ width: window.innerWidth, height: window.innerHeight }))
    }

    sendGA = (pathname) => {
        if (process.env.NODE_ENV === 'production') {
            ReactGA.pageview(pathname)
        }
    }

    render() {
        const { menu } = this.props
        if (!menu || menu && !menu.length) return <div />
        return (
            <ErrorBoundary>
                <Router history={history}>
                    <MainPage>
                        <Route path="*" component={SideNav} />
                        <PageWrap>
                            <Switch>
                                <PageRoute exact path='/' component={Home} />
                                <PageRoute exact path='/work/project/:slug?' component={ProjectDetail} />
                                <PageRoute exact path='/work/:category?/:subCategory?' component={Projects} />
                                <PageRoute exact path='/team/:location(houston|dallas|tulsa|nashville)?' component={People} />
                                <PageRoute exact path='/team/careers' component={Careers} />
                                <PageRoute exact path='/team/careers/general-application' component={GeneralApplication} />
                                <PageRoute exact path='/team/careers/:position?' component={JobDetail} />
                                <PageRoute exact path='/team/:person?' component={PersonDetail} />
                                <PageRoute exact path='/studio' component={StudioOverview} />
                                <PageRoute exact path='/studio/awards' component={Awards} />
                                <PageRoute exact path='/studio/contact' component={Contact} />
                                <PageRoute exact path='/news' component={News} />
                                <PageRoute exact path='/news/:slug?' component={NewsDetail} />
                                <PageRoute exact path='/insights' component={Insights} />
                                <PageRoute exact path='/:privacy(copyright|terms-of-use)?' component={Flatpage} />
                                <PageRoute exact path='/:page/:category?/:subCategory?' component={PageView} />
                            </Switch>
                        </PageWrap>
                        <Route path="*" component={Footer} />
                    </MainPage>
                </Router>
            </ErrorBoundary>
        )
    }
}

const PageWrap = styled.main`
    flex: 1;
`

const MainPage = styled.div`
    display: ${props => props.ie ? 'block' : 'flex'};
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
    max-width: 100vw;
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        projects: state.project.list,
        team: state.team.list,
        menu: state.content.menu,
    }
}
const connectedApp = connect(mapStateToProps)(App)
export { connectedApp as App }
