import React from 'react'
import styled from 'styled-components'
import { devices, margins, paddings, Container } from './'
import colors from '../../css/colors.scss'
import fonts from '../../css/fonts.scss'

const Block = styled.div`
    ${margins}
    ${paddings}
    &:before, &:after {
        content: '';
        display: table;
        clear: both;
    }
`

const ImageCard = styled.div`
    width: 100%;
    height: ${({ height = 200 }) => height}px;
    background: center / ${props => props.orientation || 'cover'} no-repeat url(${props => props.background});
    ${margins}
    @media ${devices.mobile} {
        /* height: 210px; */
    }
`

const SlideImageCardComp = styled(ImageCard)`
    height: 440px;
    margin-bottom: 0;
    background-position: top;
    @media ${devices.mobile} {
        height: 300px;
    }
`

const SlideCaption = styled.div`
    font-size: 8px;
    padding-top: 10px;
    padding-right: 40%;
`

const SlideImageCard = styled(({ caption, background }) => {
    return <div>
        <SlideImageCardComp background={background} />
        {caption && <SlideCaption>{caption}</SlideCaption>}
    </div>
})

const Section = styled(Container)`
    padding-top: 12px;
    padding-bottom: 10px;
    margin-top: ${({ margintop = 0 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 0 }) => marginbottom}px;
`

const ArticleWrap = styled.div`
    max-width: ${props => props.maxwidth || 'unset'};
    ${margins}
    padding-bottom: 60px;
    @media ${devices.mobile} {
        padding-left: 0;
        padding-right: 0;
    }
`

const ArticleTextWrap = styled.div`
    ${margins}
    ${props => !props.nopadding && 'padding-right: 60px;'}
    /* overflow: hidden; */
    font-size: ${fonts.fontMedium};
    padding-top: 60px;
    p:not(:last-of-type) {
        margin-bottom: 18px;
    }
    ul {
        padding: 5px 0px 5px 50px;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: auto !important;
        th, td {
            padding: 5px;
            border: 1px solid #414042;
        }
    }
    @media ${devices.mobile} {
        padding-right: 0;
        font-size: ${fonts.fontSmall};
    }
    li {
        margin-bottom: 10px;
    }
`

const Hr = styled.div`
    ${margins}
    width: 100%;
    height: 1px;
    background-color: #a0a0a1;
`

const ArticleInfo = styled(Block)`
    padding: 36px 25px;
    border: 1px solid #414042;
`

const Button = styled.button`
    background-color: transparent;
    background-image: url('/static/images/button.svg');
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 21px;
    font-family: 'VictorSerif';
    border: none;
    border-radius: 0;
    color: ${({ color = colors.maroon }) => color};
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    outline: none;
    position: relative;
    user-select: none;
    padding: 10px 30px;
    /* line-height: 14px; */
    margin: 50px 0;
    width: 139px;
    height: 47px;
    ${({ inverted = false }) => inverted && `
        background-color: ${colors.maroon};
        color: #fff;
    `}

    transition: color 0.2s ease-in;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            color: ${colors.purple};
        }
    }
`

const Separator = styled.hr`
    border: ${({ thickness }) => thickness || 0.5}px solid ${colors.maroon};
    margin: 60px 0;
`

const Modal = styled.div`
    height: 100vh;
    width: 100vw;
    background: ${({ background }) => background || 'black'};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    ${({ show }) => !show && 'display: none !important;'}

    iframe {
        height: calc(100vh - 40px) !important;
    }
`

const CloseContainer = styled.div`
    height: 40px;
    width: 40px;
`

const CloseVideo = styled.div`
    color: ${colors.white};
    position: absolute;
    right: 16px;
    font-size: 35px;
    cursor: pointer;
`

export const ViewButton = styled.div`
    background: center / cover no-repeat url(${props => `/static/images/${props.image}.svg`});
    width: 45px;
    height: 31px;
    cursor: pointer;
    margin-left: 15px;

    &:before {
        content: "";
        background: center / cover no-repeat url(${props => `/static/images/${props.image}.svg`});
        width: 45px;
        height: 31px;
        opacity: 0;
        display: block;
        transition: opacity 0.2s ease-in;
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover:before {
            opacity: 1;
        }
    }
`

export {
    Block,
    Button,
    ImageCard,
    SlideImageCard,
    ArticleWrap,
    ArticleInfo,
    Hr,
    Section,
    ArticleTextWrap,
    Separator,
    Modal,
    CloseContainer,
    CloseVideo,
}
