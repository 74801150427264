import { createConstants } from '../_helpers/utils'

export const projectTypes = createConstants(
    'PROJECT_LIST_REQUEST',
    'PROJECT_LIST_SUCCESS',
    'PROJECT_PAGE_REQUEST',
    'PROJECT_PAGE_SUCCESS',
    'CLEAR_PROJECT',
    'CLEAR_PROJECT_LIST',
    'FILTER_PROJECTS',
    'CHANGE_PROJECT_LAYOUT',
    'SET_CARD_HEIGHT',
)
