import React, { Component } from 'react'
import styled from 'styled-components'
import {
    devices,
    minDevices,
    Header,
    UpperHeader,
    Image,
} from '../../Common'
import SlideDown from 'react-slidedown'
import fonts from '../../../css/fonts.scss'

class SlideItems extends Component {
    state = {
        open: null,
    }

    setOpen = (openId) => {
        this.state.open === openId ? this.setState({ open: null }) : this.setState({ open: openId })
    }

    render() {
        const { slideDowns, subTitle } = this.props
        const { open } = this.state
        const mobile = window.innerWidth < 480
        return (
            <React.Fragment>
                <Column>
                    <SubTitle margintop={30}>{subTitle}</SubTitle>
                    <Image src={'/static/images/underline.svg'} height={mobile ? 12 : 17} />
                    {/* <div> */}
                    {slideDowns.map((s, i) =>
                        <React.Fragment key={i}>
                            <Title
                                marginbottom={10}
                                margintop={i > 0 && i - 1 === open ? 40 : 0}
                                fontSize={31}
                                onClick={() => this.setOpen(i)}
                            >
                                {s.heading}
                            </Title>
                            <SlideDown className={'my-dropdown-slidedown'}>
                                {i === open && <Body dangerouslySetInnerHTML={{ __html: s.body }} />}
                            </SlideDown>
                        </React.Fragment>
                    )}
                    {/* </div> */}
                </Column>
            </React.Fragment>
        )
    }
}

const Column = styled.div`
    @media ${minDevices.tablet} {
        width: calc(${props => props.width}% - 15px);
    }

    @media ${devices.mobile} {
        margin-bottom: 40px;
    }
    /* padding: 15px 0; */

    @media ${minDevices.desktop} {
        /* height: 525px; */
    }
`

const Title = styled(Header)`
    cursor: pointer;
`

const Body = styled.div`
    @media ${devices.mobile} {
        font-size: 20px;
    }
`

const SubTitle = styled(UpperHeader)`
    font-size: ${fonts.fontSmall};
    @media ${devices.mobile} {
        font-size: 20px;
    }
    /* background: center / cover no-repeat url('/static/images/underline.svg'); */
`

export { SlideItems }