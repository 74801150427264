import React, { Component } from 'react'
import styled from 'styled-components'
import {
    ArticleTextWrap,
    Block,
    devices,
    Grid,
    H3Header,
    ImageCard,
    UpperHeader,
} from '../../Common'
import LazyLoad from 'react-lazy-load'
import fonts from '../../../css/fonts.scss'

const ArticleContent = ({ pageBlock, iframeHeight, loadIframe, imageHeight }) => (
    <React.Fragment>
        {pageBlock.type === 'LEFT' && pageBlock.title ? (
            <UpperHeader margintop={0} marginbottom={20}>{pageBlock.title}</UpperHeader>
        ) : ''}
        {pageBlock.image ? (
            <ImageCard height={imageHeight ? imageHeight : 600} background={pageBlock.image} data-clippath={'alt1'} />
        ) : ''}
        {pageBlock.small_title && pageBlock.id !== 43 ? (
            <UpperHeader marginbottom={20}>
                {pageBlock.small_title}
            </UpperHeader>
        ) : ''}
        {pageBlock.content ? <ArticleTextWrap
            nopadding={pageBlock.type === 'SIDEBAR'}
            dangerouslySetInnerHTML={{ __html: pageBlock.content }}
        /> : ''}
        {pageBlock.iframe_url ? (
            <iframe
                src={pageBlock.iframe_url}
                width='100%'
                height={iframeHeight}
                scrolling='auto'
                frameBorder='0'
                style={{ maxWidth: '740px', marginTop: '15px' }}
                onLoad={loadIframe}
            />
        ) : ''}
    </React.Fragment>
)

const BlockComp = ({ pageBlock, id, imageHeight, textPadding, LinkComp, columns }) => (
    <FlexColumn
        key={`#${id}`}
        margintop={pageBlock[`image${id}`] ? 0 : 30}
        marginbottom={pageBlock[`image${id}`] ? 7.5 : 0}
        marginright={Number(id) === columns ? 0 : 15}
    >
        {pageBlock[`sub_title${id}`] ? (
            <SubTitle margintop={0} marginbottom={20}>{pageBlock[`sub_title${id}`]}</SubTitle>
        ) : ''}
        {pageBlock[`image${id}`] &&
            <LazyLoad height={imageHeight} offset={100}>
                <ImageCard
                    background={pageBlock[`image${id}`]}
                    height={imageHeight}
                    orientation={'cover'}
                    data-clippath={'alt1'}
                />
            </LazyLoad>
        }
        <TextContent paddingRight={textPadding}>
            {pageBlock[`small_title${id}`] &&
                <UpperHeader>
                    {pageBlock[`small_title${id}`]}
                </UpperHeader>
            }
            {pageBlock[`link_title${id}`] &&
                <H3Header>
                    {pageBlock[`link_title${id}`]}
                </H3Header>
            }
            {pageBlock[`content${id}`] &&
                <Block paddingbottom={20} dangerouslySetInnerHTML={{ __html: pageBlock[`content${id}`] }} />
            }
            {pageBlock[`linked_subpage_description${id}`] &&
                <LinkComp as='div'>{pageBlock[`linked_subpage_description${id}`]}</LinkComp>
            }
        </TextContent>
    </FlexColumn>
)

class SimpleBlock extends Component {
    render() {
        return (
            <React.Fragment>
                {this.props.columns === 1 ? <ArticleContent {...this.props} /> : <BlockComp {...this.props} />}
            </React.Fragment>
        )
    }
}

const FlexColumn = styled(Grid.Column)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    margin-right: ${props => props.marginright}px;
    @media ${devices.mobile} {
        margin-bottom: ${props => props.marginbottom}px;
        margin-right: 0;
    }
`

const TextContent = styled.article`
    /* padding-top: 1rem; */
    padding-right: ${({ paddingRight = '2rem' }) => paddingRight};
    flex: 1;
    display: flex;
    flex-direction: column;
    .before-top {
        margin-bottom: auto;
    }

    font-size: ${fonts.fontMedium};

    @media only screen and ${devices.desktop} {
        font-size: 35px;
    }

    @media only screen and ${devices.mobile} {
        padding: 0;
    }
`

const SubTitle = styled(UpperHeader)`
    font-size: ${fonts.fontSmall};
`

export { SimpleBlock }
