import React, { Component } from 'react'
import styled from 'styled-components'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel as CarouselComp } from 'react-responsive-carousel'
import { VideoModal } from '../../'
import { devices, Image } from '../../Common'
import VimeoPlayer from 'react-player/lib/players/Vimeo'
import fonts from '../../../css/fonts.scss'
import colors from '../../../css/colors.scss'
import scrollToComponent from 'react-scroll-to-component'
import { Link } from 'react-router-dom'

class SimpleCarousel extends Component {
    state = {
        activeSlide: this.props.activeSlide || 0,
        slides: this.props.slides || [],
        showModal: false,
        content: this.props.content,
        dimensions: this.props.dimensions,
        height: '',
        width: '',
        verticalPadding: this.props.verticalPadding || 0,
        horizontalPadding: this.props.horizontalPadding || 0,
        lightbox: this.props.lightbox || false,
        updateHeight: this.props.updateHeight,
    }

    carousel = React.createRef()

    componentDidMount() {
        const { lightbox } = this.state
        if (!lightbox) {
            this.updateDimensions()
            window.addEventListener('resize', this.updateDimensions);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            slides: nextProps.slides,
            content: nextProps.content,
            dimensions: nextProps.dimensions,
            verticalPadding: nextProps.verticalPadding,
            horizontalPadding: nextProps.horizontalPadding,
            lightbox: nextProps.lightbox,
            activeSlide: nextProps.activeSlide || this.state.activeSlide,
            height: nextProps.height || this.state.height,
            width: nextProps.width || this.state.width,
        })
        if (!nextProps.lightbox) this.updateDimensions()
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateDimensions)
    }

    updateDimensions = () => {
        const ratio = 898 / 1469
        const width = this.carousel.current.offsetWidth
        const height = ratio * width
        this.setState({ height, width });
    }

    openModal = (video_url) => this.setState({ showModal: true, video_url })

    closeModal = () => this.setState({ showModal: false })

    next = () => this.setState(state => ({ activeSlide: state.activeSlide + 1 }))

    prev = () => this.setState(state => ({ activeSlide: state.activeSlide - 1 }))

    updateCurrentSlide = (index) => {
        const { activeSlide } = this.state

        if (activeSlide !== index) {
            this.setState({ activeSlide: index })
        }
    }

    render() {
        const { height, width, activeSlide, slides, lightbox, videoPreview, refProp } = this.state
        const mobile = window.innerWidth < 480

        const scrollDefaults = {
            offset: 0,
            ease: 'out-cube',
        }
        return (
            <CarouselContainer ref={this.carousel} lightbox={lightbox}>
                <StyledCarousel
                    autoPlay={false}
                    height={height}
                    infiniteLoop={slides.length > 1}
                    interval={5000}
                    onChange={this.updateCurrentSlide}
                    selectedItem={activeSlide}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    stopOnHover={false}
                    transitionTime={1200}
                    carouselwidth={width}
                    video={videoPreview}
                >
                    {slides.map((img, i) =>
                        <div key={img.image} data-clippath={activeSlide === i ? 'alt1' : undefined}>
                            {
                                img.image && !img.video_url ? (
                                    img.linked_page ?
                                        <Link to={img.linked_page}>
                                            <SlideImageCard
                                                height={height}
                                                background={img.image}
                                                orientation={img.orientation === 'portrait' ? 'contain' : 'cover'}
                                                lightbox={lightbox}
                                            />
                                        </Link> :
                                        <SlideImageCard
                                            height={height}
                                            background={img.image}
                                            orientation={img.orientation === 'portrait' ? 'contain' : 'cover'}
                                            lightbox={lightbox}
                                        />
                                ) : ''
                            }
                            {
                                img.video_url ? (
                                    <VideoThumbnail
                                        image={img.video_thumbnail || img.youtube_thumbnail || img.vimeo_thumbnail || img.image}
                                        height={mobile ? '100vh' : height}
                                    >
                                        {(!mobile && img.video_thumbnail_url) &&
                                            <BackgroundPlayer
                                                url={img.video_thumbnail_url}
                                                volume={0}
                                                controls={false}
                                                height={mobile ? '100vh' : height}
                                                width="109%"
                                                muted
                                                playing
                                                loop
                                                playsinline={true}
                                            />
                                        }
                                        <VideoPlayButton
                                            onClick={() => this.openModal(img.video_url)}
                                        />

                                        <ScrollButton aria-label='featured projects' onClick={() => scrollToComponent(refProp, scrollDefaults)}>
                                            Featured Projects
                                            <ImageIcon src={'/static/images/down-arrow-white.svg'} height={28} alt='Arrow pointing down to featured projects carousel' />
                                        </ScrollButton>
                                    </VideoThumbnail>
                                ) : ''
                            }
                        </div>
                    )}
                </StyledCarousel>
                {slides.length > 1 &&
                    <CarouselInfo lightbox={lightbox} height={height} width={width}>
                        <Caption
                            as={slides[activeSlide] && slides[activeSlide].linked_page ? Link : 'div'}
                            to={slides[activeSlide] && slides[activeSlide].linked_page ? slides[activeSlide].linked_page : ''}
                        >
                            {slides[activeSlide] ? slides[activeSlide].image_caption : ''}
                        </Caption>
                        <CarouselCounter>
                            <Arrows>
                                <LeftArrow onClick={this.prev} role='button' aria-label='Preivous slide' />
                                <CarouselNumbers>{`${activeSlide + 1}/${slides.length}`}</CarouselNumbers>
                                <RightArrow onClick={this.next} role='button' aria-label='Next slide' />
                            </Arrows>
                        </CarouselCounter>
                    </CarouselInfo>
                }
                <VideoModal
                    url={this.state.video_url}
                    showModal={this.state.showModal}
                    closeModal={this.closeModal}
                    mobile={mobile}
                />
            </CarouselContainer>
        )
    }
}

const CarouselContainer = styled.div`
    /* ${({ lightbox }) => !lightbox ? 'position: relative;' : ''} */
`

const ImageIcon = styled(Image)`
    cursor: pointer;
`

const ScrollButton = styled.div`
    font-size: ${fonts.fontSmall};
    position: absolute;
    bottom: 10%;
    right: 50px;
    cursor: pointer;
    color: ${colors.white};

    @media ${devices.mobile} {
        bottom: 3%;
        right: 20px;
    }

    display: flex;
    align-items: center;

    ${Image} {
        margin-left: 10px;
        width: auto !important;
    }
`

const BackgroundPlayer = styled(VimeoPlayer)`
    div {
        /* width: 109%; */
        iframe {
            margin: 0 !important;
        }
        @media ${devices.mobile} {
            position: relative;
            iframe {
                min-width: 100%;
                min-height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                height: 56.25vw;
                transform: translate(-50%, -50%);
                width: 177.77777778vh !important;
                box-sizing: border-box;
            }
        }
    }
`

const StyledCarousel = styled(CarouselComp)`
    /* height: ${({ height }) => height}px; */
    /* height: auto; */
    width: ${({ carouselwidth }) => carouselwidth}px;

    .slide {
        height: ${props => props.height}px;
        background: white;
        @media ${devices.mobile} {
            height: ${({ video }) => video ? '100vh' : '210px'};
        }
    }

    && {
        .control-dots {
            display: none;
        }
    }

    @media ${devices.mobile} {
        height: ${({ video }) => video ? '100vh' : '210px'};
        max-width: 100vw;
        /* width: 100vw; */
    }
`

const VideoThumbnail = styled.div`
    height: ${props => props.height}px;
    width: 100%;
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
    cursor: pointer;
    position: relative;

    @media ${devices.mobile} {
        height: 100vh;
    }
`

const VideoPlayButton = styled.div`
    width: 100px;
    height: 160px;
    background-image: url('/static/images/video-play.png');
    background-position: center;
    background-size: 100px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: absolute;
    top: 41%;
    left: 46%;

    &:hover {
        background-image: url('/static/images/video-play-outline.png');
    }

    @media ${devices.mobile} {
        /* height: 210px; */
        left: 36%;
        top: 36%;
    }
`

const ImageCard = styled.div`
    max-width: 100%;
    height: ${({ height = 200 }) => height}px;
    background: center / ${props => props.orientation || 'cover'} no-repeat url(${props => props.background});
`

const SlideImageCard = styled(ImageCard)`
    height: ${props => props.height}px;
    margin-bottom: 0;
    background-position: top;
    @media ${devices.mobile} {
        height: 210px;
    }
`

const CarouselCounter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 180px; */

    @media ${devices.mobile} {
        flex-direction: column;
    }
`

const Caption = styled.div`
    text-align: left;
    text-decoration: none;

    @media ${devices.mobile} {
        font-size: ${fonts.fontSmall};
    }
`

const CarouselNumbers = styled.div`
    @media ${devices.mobile} {
        font-size: ${fonts.fontSmall};
    }
`

const CarouselInfo = styled.div`
    font-size: ${fonts.fontSmall};
    margin-top: 20px;
    margin-bottom: 10px;

    display: flex;
    justify-content: space-between;

    ${({ lightbox, width, height }) => lightbox ? (`
        ${CarouselNumbers} {
            color: white;
            position: absolute;
            bottom: 40px;
            font-size: ${fonts.fontSmall};
            left: ${((window.innerWidth - width) / 4) - 17}px;
            bottom: ${((window.innerHeight - height) / 2) - 2}px;
        }

        ${LeftArrow}, ${RightArrow} {
            background: center / contain no-repeat url(/static/images/white-left-arrow.svg);
            position: absolute;
            top: 50%;
        }

        ${LeftArrow} {
            // (horizontal margins) / 4 (2 = 1 side, 4 = half of one side) - half of arrow width
            left: ${((window.innerWidth - width) / 4) - (37 / 2)}px;
        }

        ${RightArrow} {
            right: ${((window.innerWidth - width) / 4) - (37 / 2)}px;
        }

        @media only screen and ${devices.mobile} {
            ${CarouselNumbers} {
                bottom: 20px;
                left: calc(50% - 25px);
            }

            ${LeftArrow}, ${RightArrow} {
                top: unset;
                bottom: 25px;
            }

            ${LeftArrow} {
                left: 20px;
            }

            ${RightArrow} {
                right: 20px;
            }
        }
    `) : ''}
`

const Arrows = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100px;
`

const Arrow = styled.div`
    background: center / contain no-repeat url(/static/images/left-arrow.svg);
    width: 23px;
    height: 13px;
    cursor: pointer;
`

const LeftArrow = styled(Arrow)`

`

const RightArrow = styled(Arrow)`
    transform: rotate(180deg);
`

export { SimpleCarousel }
