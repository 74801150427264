import React, { PureComponent } from 'react'

class ScrollCollision extends PureComponent {
    constructor (props){
        super(props)
        this.wrapperRef = React.createRef()
    }

    state = {
        allBlocks: [],
        blockClass: null,
        style: {
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            width: this.props.width || '400px',
        },
        originOuter: 0,
        cloneOuter: 0,
        base: 100,
        element: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            height: 0,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({
            style: {
                ...this.state.style,
                width: nextProps.width || '400px',
            },
            allBlocks: nextProps.blocks,
        }, () => !this.updateElement() ? this.testCollision() : '')
    }

    componentDidMount = () => {
        const blocks = document.querySelectorAll('[data-clippath]')
        this.setState({
            ...this.state,
            allBlocks: blocks,
            cloneOuter: this.state.base
        }, () => this.updateElement())

        window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount = () => {
        window.removeEventListener("scroll", this.handleScroll)
    }

    componentDidUpdate = () => {
        this.updateElement()
    }

    handleScroll = () => {
        this.testCollision()
    }

    testCollision = () => {
        const {allBlocks, element, base} = {...this.state}
        let blocks = allBlocks
        const blockQuery = document.querySelectorAll('[data-clippath]')
        if (!allBlocks || allBlocks.length !== blockQuery.length) {
            blocks = blockQuery
            this.setState({allBlocks: blocks})
        }
        for (let i = 0; i < blocks.length; i++) {
            const block = this.getBlockInfos(blocks[i])
            let newOrigin = 0
            let newClone = 0
            if (
                (element.left >= block.left && element.left <= block.right)
                || (block.left < 0 && element.left < -element.left && element.left > block.right)
            ) {
                if (element.bottom >= block.top && element.top <= block.top && this.isInBoundaries()) {
                    newOrigin =  block.top - element.bottom
                    newClone = block.top - element.top
                    return this.updateOverlay(newOrigin, newClone, block.class)
                }

                if (element.bottom >= block.bottom && element.top <= block.bottom && this.isInBoundaries()) {
                    newOrigin = block.bottom - element.top
                    newClone = block.bottom - element.bottom
                    return this.updateOverlay(newOrigin, newClone, block.class)
                }

                if (element.top > block.top && element.bottom < block.bottom) {
                    return this.updateOverlay(-base, 0, block.class)
                }
            }
        }
        return this.updateOverlay(0, base, null)
    }

    updateElement = () => {
        const newElem = this.getBlockInfos(this.wrapperRef.current)
        if (
            this.state.element.top !== newElem.top
            || this.state.element.bottom !== newElem.bottom
            || this.state.element.left !== newElem.left
            || this.state.element.right !== newElem.right
        ) {
            const element = {
                top: newElem.top,
                bottom: newElem.bottom,
                left: newElem.left,
                right: newElem.right,
                height: newElem.bottom - newElem.top
            }
            this.setState({
                ...this.state,
                element: element
            }, () => this.testCollision())
            return true
        }
    }

    updateOverlay = (origin, clone, blockClass) => {
        const tempOrigin = this.getPercent(origin)
        const tempClone = this.getPercent(clone)

        this.setState({
            ...this.state,
            blockClass: blockClass,
            originOuter: tempOrigin,
            cloneOuter: tempClone
        })
    }

    isInBoundaries = () => {
        const {originOuter, cloneOuter, base} = {...this.state}
        return originOuter <= base && originOuter >= - base && cloneOuter <= base && cloneOuter >= - base
    }

    getPercent = (value) => {
        const {base} = {...this.state}
        let percent = (value * base) / this.state.element.height
        if (percent > base) percent = base
        else if (percent < - base) percent = - base
        return percent
    }

    getBlockInfos = (elem) => {
        const position = elem.getBoundingClientRect()
        const blockClass = elem.dataset? elem.dataset.clippath : null
        const element = {
            top: position.top,
            bottom: position.bottom,
            left: position.left,
            right: position.right,
            class: blockClass
        }

        return element
    }

    render() {

        const {className, children, style} = {...this.props}

        return (
            <div className={"clippath-wrapper " + className} ref={this.wrapperRef} style={style}>
                <div className="clippath-origin" style={{...this.state.style, overflow: 'hidden', transform: "translateY(" + this.state.originOuter +  "%)" }}>
                    <div className="clippath-inner" style={{...this.state.style, overflow: 'auto', transform: "translateY(" + - this.state.originOuter +  "%)" }}>
                        <div>{children}</div>
                    </div>
                </div>
                <div className={"clippath-clone " + this.state.blockClass} style={{...this.state.style, overflow: 'hidden', transform: "translateY(" + this.state.cloneOuter +  "%)" }}>
                    <div className="clippath-inner" style={{...this.state.style, overflow: 'auto', transform: "translateY(" + - this.state.cloneOuter +  "%)" }}>
                        <div>{children}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export { ScrollCollision }
