import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
    NotFound,
    PageBlock,
    SimpleCarousel,
}
from '../'
import {
    ArticleWrap,
    ArticleTextWrap,
    Container,
    Block,
    devices,
    Grid,
}
from '../Common'
import { loadPage } from '../../_actions'
import DocumentMeta from 'react-document-meta'

class PageView extends Component {
    componentDidMount() {
        const { category, subCategory, page } = this.props.match.params
        this.props.dispatch(loadPage(subCategory || category || page))
    }

    render() {
        const { page, notFound, ie, dimensions } = this.props.content
        const meta = {
            title: `Inventure | ${page.meta_title || page.title}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords:  page.meta_keywords
                }
            }
        }
        if (notFound) return <NotFound />

        return (
            <React.Fragment>
                <DocumentMeta { ...meta } />
                <div ref={ this.newRef }>
                    {page.hero_carousel && page.hero_carousel.length > 0 ? (
                        <SimpleCarousel innerRef={ this.setImageHeight } slides={ page.hero_carousel } id='imageHeight' dimensions={ dimensions } />
                    ) : ''}
                    {page.intro_image ? (
                        <Hero innerRef={ this.setImageHeight } background={ page.intro_image } id='imageHeight' />
                    ) : ''}
                </div>
                <Container>
                    <Grid marginbottom={ 45 } columns={ 1 }>
                        <ContentWrap width={ '100%' }>
                            { page.intro ? <IntroText dangerouslySetInnerHTML={{ __html: page.intro }} marginbottom={ 0 } /> : '' }
                            {page.page_blocks ? (
                                <ArticleWrapColumn margintop={ 40 } width={ '100%' }>
                                    <ArticleWrap>
                                        {page.page_blocks.filter(block => block.type === 'LEFT').map((pageBlock, i) =>
                                            <Block key={i.toString()} marginbottom={ 30 }>
                                                <PageBlock pageBlock={ pageBlock } url={ page.url } ie={ ie }/>
                                            </Block>
                                        )}
                                    </ArticleWrap>
                                </ArticleWrapColumn>
                            ) : ''}
                        </ContentWrap>
                    </Grid>
                </Container>
            </React.Fragment>
        )
    }
}

const ContentWrap = styled.div`
    width: ${props => props.width};
    @media ${devices.mobile} {
        width: 100%;
    }
`

const Hero = styled.div`
    height: 70vh;
    max-height: 600px;
    width: 100%;
    background: center / cover no-repeat url(${props => props.background});
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`

const ArticleWrapColumn = styled(Grid.Column)`
    @media (min-width: 800px) {
        width: 100%;
    }
`

const IntroText = styled(ArticleTextWrap)`
    font-weight: 500;
    font-size: 16px;
    max-width: 750px;
    margin-top: 30px;
`

function mapStateToProps(state) {
    return {
        content: state.content
    }
}

const connectedPageView = connect(mapStateToProps)(PageView)
export { connectedPageView as PageView }
