import { contentTypes } from '../_constants'
import { BrowserDetect } from '../_helpers/utils'
// import { stat } from 'fs'

const initialState = {
    page: {},
    menu: [],
    awards: [],
    ie: BrowserDetect() === 'Explorer',
    menuOpen: false,
    notFound: false,
    newsletterMessage: '',
    newsletterError: '',
    dimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    values: [],
    animations: [],
    animation: {},
    termsAndCopyright: {},
    logoHidden: false,
}

export function content(state = initialState, action) {
    switch (action.type) {
        case contentTypes.PAGE_DATA_SUCCESS:
            return {
                ...state,
                page: action.payload,
                notFound: false,
            }
        case contentTypes.PAGE_DATA_FAILURE:
            return {
                ...state,
                notFound: true,
            }
        case contentTypes.MENU_LINKS_SUCCESS:
            return {
                ...state,
                menu: action.payload,
            }
        case contentTypes.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                newsletterMessage: action.payload,
                newsletterError: '',
            }
        case contentTypes.SUBSCRIBE_FAILURE:
            return {
                ...state,
                newsletterMessage: '',
                newsletterError: action.payload,
            }
        case contentTypes.TOGGLE_MENU:
            return {
                ...state,
                menuOpen: action.payload,
            }
        case contentTypes.AWARD_LIST_SUCCESS:
            return {
                ...state,
                awards: action.payload,
            }
        case contentTypes.RESIZE:
            return {
                ...state,
                dimensions: action.payload,
            }
        case contentTypes.ANIMATION_LIST_SUCCESS:
            return {
                ...state,
                animations: action.payload,
            }
        case contentTypes.ANIMATION_SUCCESS:
            return {
                ...state,
                animation: action.payload,
            }
        case contentTypes.TC_SUCCESS:
            return {
                ...state,
                termsAndCopyright: action.payload,
            }
        case contentTypes.LOGO_SHOW:
            return {
                ...state,
                logoHidden: action.payload,
            }
        default:
            return state
    }
}
