import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { postContactForm } from '../../_actions'
import { Button } from '../Common'
import { FormField, validateEmail, validateRequired } from './Forms'

class ContactForm extends Component {

    state = {
        buttonText: 'Submit',
        sent: false,
    }

    handleSubmit = (data) => {
        this.props.dispatch(postContactForm(data))
        this.setState({ buttonText: 'Sent', sent: true })
    }

    render() {
        const { handleSubmit } = this.props
        const { buttonText, sent } = this.state
        return (
            <form onSubmit={handleSubmit(this.handleSubmit)}>
                <Field
                    name='full_name'
                    type='text'
                    component={FormField}
                    label='Full Name'
                    required
                    validate={[validateRequired,]} />
                <Field
                    name='email'
                    type='email'
                    component={FormField}
                    label='Email Address'
                    required
                    validate={[validateRequired, validateEmail]} />
                <Field
                    name='company_name'
                    type='text'
                    component={FormField}
                    label='Company'
                    required
                    validate={[validateRequired,]} />
                <Field
                    name='message'
                    component={FormField}
                    label='Message'
                    required
                    validate={[validateRequired,]} />
                <Button type='submit' disabled={sent}>{buttonText}</Button>
            </form>
        )
    }
}

const connectedContactForm = reduxForm({ form: 'contact' })(ContactForm)

export { connectedContactForm as ContactForm }