import { formTypes } from '../_constants'

const initialState = {
    message: '',
    error: '',
}

export function form(state = initialState, action) {
    switch (action.type) {
        case formTypes.POST_GENERAL_APPLICATION_SUCCESS:
        case formTypes.POST_CONTACT_FORM_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: '',
            }
        case formTypes.POST_GENERAL_APPLICATION_FAILURE:
        case formTypes.POST_CONTACT_FORM_FAILURE:
            return {
                ...state,
                message: '',
                error: action.payload,
            }
        case formTypes.RESET:
            return {
                ...state,
                message: '',
                error: '',
            }
        default:
            return state
    }
}