import React, { Component } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'

class HomepageWord extends Component {
    state = {
        isStopped: true,
        didMount: false,
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ didMount: true })
        }, this.props.index * 600)

        if (this.props.mobile) {
            setTimeout(() => {
                this.setState({ isStopped: false })
            }, this.props.index * 700)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.word !== state.word) state.word = props.word
        if (props.index !== state.index) state.index = props.index
        if (props.mobile !== state.mobile) state.mobile = props.mobile
        return state
    }

    render() {
        const { isStopped, didMount, word, index, mobile } = this.state

        const eventListeners = [
            {
                eventName: 'complete',
                callback: () => this.setState({ isStopped: true })
            }
        ]

        const defaultOptions = (animationData) => (
            {
                loop: false,
                autoplay: false,
                animationData: JSON.parse(animationData),
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                    progressiveLoad: true,
                }
            }
        )

        const wordWithPunctuation = (word, i) => {
            const prefix = i === 5 ? 'and ' : ''
            const punctuation = i < 4 ? ', ' : i === 5 ? '.' : ''

            return `${prefix}${word}${punctuation}`
        }

        const height = mobile ? 70 : 100

        return (
            <Word
                onMouseEnter={() => !mobile && this.setState({ isStopped: false })}
                onMouseLeave={() => !mobile && this.setState({ isStopped: true })}
                mounted={didMount}
                index={index}
            >
                <Lottie
                    options={defaultOptions(word.animation)}
                    height={height}
                    width={height}
                    speed={0.7}
                    isClickToPauseDisabled={true}
                    isStopped={isStopped}
                    eventListeners={mobile ? eventListeners : []}
                    style={{ position: 'absolute', 'zIndex': '-1' }}
                />
                &nbsp;{wordWithPunctuation(word.text, index)}
            </Word>
        )
    }
}

const Word = styled.div`
    position: relative;
    display: inline-block;
    cursor: default;

    transition: opacity 0.5s ease-in;
    opacity: ${({ mounted }) => mounted ? 1 : 0};
    /* transition-delay: calc(${({ index }) => index} * 600ms); */
`

export { HomepageWord }
