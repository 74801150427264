import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import {
    loadPeopleList,
    loadPage,
    // filterPeople,
    changePeopleLayout
} from '../../_actions'
import DocumentMeta from 'react-document-meta'
import { PeopleGrid, PeopleList } from '../'
import { Container, devices, ViewButton } from '../Common'

class People extends Component {
    componentDidMount() {
        const { dispatch, page, team } = this.props
        const { location } = this.props.match.params
        // dispatch(filterPeople(team, location))
        if (page.slug !== 'team') dispatch(loadPage('team'))
        if (!team.length) dispatch(loadPeopleList(location))
    }

    changeView = (layout, columns = 1) => {
        const { dispatch } = this.props
        dispatch(changePeopleLayout({ layout, columns }))
    }

    render() {
        const { page, people } = this.props
        const { layout, columns } = this.props.view
        let title = this.props.match.params.location
        if (title) title = title.split('-').map(e => e[0].toUpperCase() + e.slice(1)).join(' ')
        const meta = {
            title: page.meta_title || `Inventure | ${title || 'Team'}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        const isMobile = window.innerWidth < 480

        return (
            <Container margintop={50} mobilepaddingtop={60}>
                <DocumentMeta {...meta} />
                <PeopleViewChangeContainer>
                    <PeopleViewChangeOptions>
                        <PeopleView role='button' aria-label='Grid' image={`tiles${layout === 'grid' ? '-active' : ''}`} onClick={() => this.changeView('grid', 2)} />
                        {!isMobile && <PeopleView role='button' aria-label='Small Grid' image={`small-tiles${layout === 'small-grid' ? '-active' : ''}`} onClick={() => this.changeView('small-grid', 4)} />}
                        <PeopleView role='button' aria-label='List' image={`list${layout === 'list' ? '-active' : ''}`} onClick={() => this.changeView('list')} />
                    </PeopleViewChangeOptions>
                </PeopleViewChangeContainer>
                <PeopleContainer>
                    {/* TODO: add loading spinner while getting list of people */}
                    {layout === 'list' ? (
                        <PeopleList people={people} />
                    ) : (
                            <PeopleGrid people={people} columns={columns} />
                        )}
                </PeopleContainer>
            </Container>
        )
    }
}

const PeopleViewChangeContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 50px;
    margin-right: 20px;

    @media ${devices.desktop} {
        margin-right: 0;
    }
`

const PeopleContainer = styled.div``

const PeopleViewChangeOptions = styled.div`
    display: flex;
`

const PeopleView = styled(ViewButton)`
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        team: state.team.list,
        people: state.team.list,
        view: state.team.view,
    }
}

const connectedPeople = connect(mapStateToProps)(People)
export { connectedPeople as People }
