import React from 'react'
import { Field } from 'redux-form/immutable'

export const FormField = ({ input, label, type, placeholder }) => (
    <div className='input-wrap'>
        <label>{label}</label>
        {input.name === 'message' || input.name === 'why_are_you_perfect'
            ? <textarea {...input} placeholder={placeholder} type={type} />
            : <input {...input} placeholder={placeholder} type={type} />
        }
    </div>
)

export const validateRequired = value => {
    return (value ? undefined : 'Required')
}

export const validateEmail = value => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined
}

export const validatePhoneNumber = value => {
    return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
}

export const BaseFields = () => (
    <React.Fragment>
        <Field
            name='first_name'
            type='text'
            component={FormField}
            label='First Name'
            required
            validate={[validateRequired,]} />
        <Field
            name='last_name'
            type='text'
            component={FormField}
            label='Last Name'
            required
            validate={[validateRequired,]} />
        <Field
            name='email'
            type='email'
            component={FormField}
            label='Email'
            required
            validate={[validateRequired, validateEmail]} />
        <Field
            name='phone'
            type='tel'
            component={FormField}
            label='Phone'
            required
            validate={[validateRequired, validatePhoneNumber]} />
    </React.Fragment>
)