import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'
import { isAfter, format } from 'date-fns'
import {
    Header,
    Container,
    UnstyledLink,
    Image,
    devices,
    ArticleWrap,
    Block,
    Separator,
} from '../Common'
import { PageBlock } from '../Content'
// import colors from '../../css/colors.scss'
import { loadJobList, loadPage, loadBenefitsList, loadGeneralApplication } from '../../_actions'

const Opening = ({ job }) => (
    <Position>
        <UnstyledLink as={Link} to={`/team/careers/${job.slug || 'general-application'}`} marginbottom={30} fontSize={31}>
            {job.title} <CareerArrow src={'/static/images/career-arrow.svg'} height={20} alt={`Go to ${job.title} description`} />
        </UnstyledLink>
        <ShortDescription>{job.short_description}</ShortDescription>
        {job.deadline && <p>Application Deadline: {format(Date.parse(job.deadline), 'MMMM d, yyyy')}</p>}
    </Position>
)

class Careers extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadJobList())
        dispatch(loadPage('careers'))
        dispatch(loadBenefitsList())
        dispatch(loadGeneralApplication())
    }

    render() {
        const { page, jobs, benefits, generalApplication } = this.props

        const meta = {
            title: page.meta_title || 'Inventure',
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50} mobilepaddingtop={60}>
                    <Header fontSize={72}>Careers</Header>
                    <Header fontSize={20} marginbottom={60}>Join Our Team</Header>
                    {page.page_blocks ? (
                        <CareerArticleWrap>
                            {page.page_blocks.map((pageBlock, i) =>
                                <React.Fragment key={i.toString()}>
                                    <Block margintop={i > 0 ? 30 : 0} marginbottom={60}>
                                        <PageBlock pageBlock={pageBlock} url={page.url} />
                                    </Block>
                                </React.Fragment>
                            )}
                        </CareerArticleWrap>
                    ) : ''}
                    <Separator thickness={0.5} />
                    <Header fontSize={31} marginbottom={40}>Benefits</Header>
                    <CareersBlock>
                        {benefits.map((b, i) =>
                            <Benefit key={i}>
                                <BenefitImage icon={b.icon} aria-label={`${b.title} icon`} />
                                {b.title}
                            </Benefit>
                        )}
                    </CareersBlock>
                    <Separator thickness={0.5} />
                    <Header marginbottom={40} fontSize={31}>Openings</Header>
                    <CareersBlock>
                        {jobs.filter(j => isAfter(Date.parse(j.deadline), new Date())).map((j, i) =>
                            <Opening key={i} job={j} />
                        )}
                        <Opening job={generalApplication} />
                    </CareersBlock>
                </Container>
            </React.Fragment>
        )
    }
}

const CareersBlock = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;

    @media only screen and (max-width: 1600px) and (min-width: 1240px) {
        &::after {
            content: "";
            flex: auto;
        }
    }
`

const ShortDescription = styled.p`
    margin-top: 15px;
`

const Benefit = styled.div`
    max-width: 20%;
    min-width: 20%;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 20%;
    margin-bottom: 20px;
    /* text-align: center; */


    @media ${devices.mobile} {
        max-width: 35%;
        min-width: 35%;
        flex: 1 0 35%;
        font-size: 17px;
        margin-right: 40px;
    }
    @media only screen and (max-width: 1600px) and (min-width: 1240px) {
        max-width: 25%;
        min-width: 25%;
        flex: 1 0 25%;
    }
`

const Position = styled.div`
    width: 50%;
    margin-bottom: 20px;
    @media ${devices.mobile} {
        width: 100%;
        margin-bottom: 50px;
    }
`

const CareerArrow = styled(Image)`
    display: inline-block;
    vertical-align: baseline;
`

const BenefitImage = styled.div`
    background: center / contain no-repeat url(${({ icon }) => icon});
    height: 200px;
    width:  200px;
    
    @media ${devices.mobile} {
        height: 150px;
        width:  150px;
    }
`

const CareerArticleWrap = styled(ArticleWrap)`
    padding-bottom: 0;
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        jobs: state.team.jobList,
        benefits: state.team.benefits,
        generalApplication: state.team.generalApplication,
    }
}

const connectedCareers = connect(mapStateToProps)(Careers)
export { connectedCareers as Careers }
