import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'

class NotFound extends Component {
    render() {
        const meta = {
            title: `Inventure | Not Found`,
        }

        return (
            <NotFoundContainer className="content" id="not-found">
                <DocumentMeta { ...meta } />
                <h2>Not Found</h2>
                <p>The page you&apos;re looking for does not exist.</p>
            </NotFoundContainer>
        )
    }
}

function mapStateToProps() {
    return {

    }
}

const connectedNotFound = connect(mapStateToProps)(NotFound)
export { connectedNotFound as NotFound }

const NotFoundContainer = styled.div`
    height: calc(100vh - 435px);
    display: flex;
    justify-content: center;
    align-items: center;
`
