import React, { Component } from 'react'
import { ImageCard } from './Blocks'


class ResponsiveImage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: '',
            width: '',
        }
        this.picture = React.createRef()
    }

    componentDidMount() {
        this.updateHeight()
        // window.addEventListener('resize', this.updateHeight.bind(this));
    }

    componentDidUpdate() {
        this.updateHeight()
    }

    updateHeight = () => {
        const { columns, orientation } = this.props
        const ratio = orientation === 'landscape' ? 6 / 4 : 5 / 7,
            width = this.picture.current.offsetWidth / columns
        const height = Math.round(width / ratio)
        return height;
    }

    render() {
        const { image } = this.props

        return (
            <ImageCard
                ref={this.picture}
                background={image}
                height={this.updateHeight}
                role='img'
                // aria-label={label}
                data-clippath={'alt1'}
            />
        )
    }
}

export { ResponsiveImage }
