import { createAction, createAsyncAction } from '../_helpers/utils'
import { contentTypes, teamTypes, projectTypes, newsTypes, formTypes } from '../_constants/'
import {
    MailChimp,
    Menu,
    Pages,
    Team,
    Office,
    Job,
    Project,
    Benefit,
    Award,
    GeneralApplication,
    Animation,
    TermsAndCopyright,
    Article,
    Brochure,
    WhitePaper,
    Presentation,
    Form,
} from '../_services'


export const loadPage = createAsyncAction(
    contentTypes.PAGE_DATA_REQUEST,
    contentTypes.PAGE_DATA_SUCCESS,
    Pages.get,
    contentTypes.PAGE_DATA_FAILURE,
)

export const loadMenu = createAsyncAction(
    contentTypes.MENU_LINKS_REQUEST,
    contentTypes.MENU_LINKS_SUCCESS,
    Menu.getList
)

export const subscribe = createAsyncAction(
    contentTypes.SUBSCRIBE_REQUEST,
    contentTypes.SUBSCRIBE_SUCCESS,
    MailChimp.post,
    contentTypes.SUBSCRIBE_FAILURE
)

export const loadAwards = createAsyncAction(
    contentTypes.AWARD_LIST_REQUEST,
    contentTypes.AWARD_LIST_SUCCESS,
    Award.getList
)

export const loadTeamPage = createAsyncAction(
    teamTypes.TEAM_PAGE_REQUEST,
    teamTypes.TEAM_PAGE_SUCCESS,
    Team.get
)

export const loadOfficeList = createAsyncAction(
    teamTypes.OFFICE_LIST_REQUEST,
    teamTypes.OFFICE_LIST_SUCCESS,
    Office.getList
)

export const loadJobList = createAsyncAction(
    teamTypes.JOB_LIST_REQUEST,
    teamTypes.JOB_LIST_SUCCESS,
    Job.getList
)

export const loadJobPage = createAsyncAction(
    teamTypes.JOB_PAGE_REQUEST,
    teamTypes.JOB_PAGE_SUCCESS,
    Job.get
)

export const loadBenefitsList = createAsyncAction(
    teamTypes.BENEFIT_LIST_REQUEST,
    teamTypes.BENEFIT_LIST_SUCCESS,
    Benefit.getList
)

export const loadProjectPage = createAsyncAction(
    projectTypes.PROJECT_PAGE_REQUEST,
    projectTypes.PROJECT_PAGE_SUCCESS,
    Project.get
)

export const loadGeneralApplication = createAsyncAction(
    teamTypes.GENERAL_APP_REQUEST,
    teamTypes.GENERAL_APP_SUCCESS,
    GeneralApplication.get
)

export const loadAnimations = createAsyncAction(
    contentTypes.ANIMATION_LIST_REQUEST,
    contentTypes.ANIMATION_LIST_SUCCESS,
    Animation.getList
)

export const loadAnimation = createAsyncAction(
    contentTypes.ANIMATION_REQUEST,
    contentTypes.ANIMATION_SUCCESS,
    Animation.get
)

export const loadTermsAndCopyright = createAsyncAction(
    contentTypes.TC_REQUEST,
    contentTypes.TC_SUCCESS,
    TermsAndCopyright.get
)

export const loadArticleList = createAsyncAction(
    newsTypes.ARTICLE_LIST_REQUEST,
    newsTypes.ARTICLE_LIST_SUCCESS,
    Article.getList
)

export const loadArticle = createAsyncAction(
    newsTypes.ARTICLE_DETAIL_REQUEST,
    newsTypes.ARTICLE_DETAIL_SUCCESS,
    Article.get
)

export const loadBrochures = createAsyncAction(
    newsTypes.BROCHURE_REQUEST,
    newsTypes.BROCHURE_SUCCESS,
    Brochure.getList
)

export const loadWhitePapers = createAsyncAction(
    newsTypes.WP_REQUEST,
    newsTypes.WP_SUCCESS,
    WhitePaper.getList
)

export const loadPresentations = createAsyncAction(
    newsTypes.PRESENTATION_REQUEST,
    newsTypes.PRESENTATION_SUCCESS,
    Presentation.getList
)

export const postContactForm = createAsyncAction(
    formTypes.POST_CONTACT_FORM_REQUEST,
    formTypes.POST_CONTACT_FORM_SUCCESS,
    Form.Contact.post,
    formTypes.POST_CONTACT_FORM_FAILURE,
)

export const postGeneralApplicationForm = createAsyncAction(
    formTypes.POST_GENERAL_APPLICATION_REQUEST,
    formTypes.POST_GENERAL_APPLICATION_SUCCESS,
    Form.GeneralApplication.post,
    formTypes.POST_GENERAL_APPLICATION_FAILURE,
)

export const toggleMenu = createAction(contentTypes.TOGGLE_MENU)
export const clearProject = createAction(projectTypes.CLEAR_PROJECT)
export const changeProjectLayout = createAction(projectTypes.CHANGE_PROJECT_LAYOUT)
export const changePeopleLayout = createAction(teamTypes.CHANGE_PEOPLE_LAYOUT)
export const clearPerson = createAction(teamTypes.CLEAR_PERSON)
export const resize = createAction(contentTypes.RESIZE)
export const setCardHeight = createAction(projectTypes.SET_CARD_HEIGHT)
export const setLogoVisibility = createAction(contentTypes.LOGO_SHOW)
export const clearArticle = createAction(newsTypes.CLEAR_ARTICLE)


export const loadProjectList = (category, subCategory) => {
    return dispatch => {
        Project.getList().then(res => {
            const { projects, order, categories } = res.data;
            const filteredList = getFilteredProjects(projects, category, subCategory, order, categories)
            dispatch(success(projects, filteredList, order, categories))
        })
    }
    function success(list, filteredList, order, categories) { return { type: projectTypes.PROJECT_LIST_SUCCESS, list, filteredList, order, categories } }
}

export const filterProjects = (allProjects, category, subCategory, order, categories) => {
    return dispatch => dispatch(filtered(getFilteredProjects(allProjects, category, subCategory, order, categories)))
    function filtered(payload) { return { type: projectTypes.FILTER_PROJECTS, payload } }
}

function getFilteredProjects(allProjects, category, subCategory, order, categories) {
    const sortType = subCategory || category
    const projects = allProjects.filter(e => {
        if (!category) return true
        if (category === 'featured') return e.featured
        for (let key in e.type) {
            if (categories[sortType] && !subCategory) {
                if (categories[sortType].includes(e.type[key].slug)) return true
            } else {
                if (e.type[key].slug === sortType) return true
            }
        }
    }).sort((a, b) => {
        if (a.featured && b.featured) return a.featured_order < b.featured_order ? -1 : 1
        else if (a.featured) return -1
        return a[order[sortType]] < b[order[sortType]] ? -1 : 1
    }).sort((a, b) => a[order[sortType]] < b[order[sortType]] ? -1 : 1)
    projects.forEach(e => e.type.sort().sort(type => {
        if (sortType) {
            if (categories[sortType] && !subCategory) {
                return categories[sortType].includes(e.name) ? -1 : 1
            } else {
                return type === e.name ? -1 : 1
            }
        }
    }))
    return projects
}

export const loadPeopleList = () => {
    return dispatch => {
        Team.getList().then(res => {
            // const filteredList = getFilteredPeople(res.data, location)
            // dispatch(success(res.data, filteredList))
            dispatch(success(res.data))
        })
    }
    function success(list, filteredList) { return { type: teamTypes.TEAM_LIST_SUCCESS, list, filteredList } }
}

export const filterPeople = (team, location) => {
    return dispatch => dispatch(filtered(getFilteredPeople(team, location)))
    function filtered(payload) { return { type: teamTypes.FILTER_PEOPLE, payload } }
}

function getFilteredPeople(team, location) {
    return team.filter(e => {
        if (!location) return true
        return e.office.slug === location
    })
}
