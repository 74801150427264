import React, { Component, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { ImageCard, devices } from '../Common'

const PeopleCard = ({ person, columns }) => {
    const [isStopped, setIsStopped] = useState(true)
    const defaultOptions = (animationData) => (
        {
            loop: false,
            autoplay: false,
            animationData: JSON.parse(animationData),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        }
    )
    const isMobile = window.innerWidth < 480
    return (
        <Card width={100 / columns} to={`/team/${person.slug}`} marginright={20}>
            {/* 566x750 */}
            <PersonImageCard
                background={person.black_and_white_picture}
                colorbackground={person.color_picture}
                orientation={'cover'}
                role='img'
                aria-label={`A picture of ${person.name}`}
                data-clippath={'alt1'}
                onMouseEnter={() => !isMobile && setIsStopped(false)}
                onMouseLeave={() => !isMobile && setIsStopped(true)}
            >
                {person.animated_svg &&
                    <Lottie
                        options={defaultOptions(person.animated_svg.animation_json)}
                        speed={0.7}
                        isClickToPauseDisabled={true}
                        isStopped={isMobile || isStopped}
                        style={{ position: 'absolute', top: 0, right: 0 }}
                    />
                }
            </PersonImageCard>
            {/*height={ columns === 2 ? '780' : '568' }*/}
            <Title>
                {person.name}
                <br />
                {person.title}
            </Title>
        </Card>
    )
}

class PeopleGrid extends Component {
    state = {
        people: this.props.people,
        columns: this.props.columns,
    }

    static getDerivedStateFromProps(props, state) {
        if (state.people !== props.people) state.people = props.people
        if (state.columns !== props.columns) state.columns = props.columns
        return state
    }

    render() {
        return (
            <GridContainer odd={this.state.people && this.state.people.length % 2 !== 0}>
                {this.state.people.map((e, i) =>
                    <PeopleCard
                        key={i}
                        person={e}
                        columns={this.state.columns}
                    />
                )}
            </GridContainer>
        )
    }
}

const PersonImageCard = styled(ImageCard)`
    padding-top: ${(373 / 268) * 100}%;
    height: unset;
    position: relative;

    &:hover {
        background: center / ${props => props.orientation || 'cover'} no-repeat url(${({ colorbackground }) => colorbackground});
    }

    @media ${devices.mobile} {
        padding-top: ${(226 / 162) * 100}%;
        height: unset;
    }
`

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: flex-start;

    @media ${devices.desktop} {
        justify-content: space-between;
    }

    ${({ odd }) => odd ? `
        &::after {
            content: "";
            flex: auto;
        }
    ` : ''}
`

const Card = styled(Link)`
    flex: 1 0 ${({ width }) => width}%;
    text-decoration: none;
    max-width: calc(${({ width }) => width}% - ${({ marginright }) => marginright}px);
    margin-right: ${({ marginright }) => marginright}px;

    @media only screen and ${devices.desktop} {
        margin-right: 0;
        max-width: calc(${({ width }) => width}% - ${({ marginright }) => marginright / 2}px);
    }
`

const Title = styled.div`
    margin-top: 15px;
    margin-bottom: 40px;

    @media ${devices.mobile} {
        font-size: 22px;
    }
`

export { PeopleGrid }
