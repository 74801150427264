import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { Link, withRouter } from 'react-router-dom'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'
import { loadPage, loadAwards } from '../../_actions'
import {
    Header,
    Container,
    devices,
    minDevices,
    FlexGrid,
    FlexGridColumn,
} from '../Common'
import colors from '../../css/colors.scss'
import fonts from '../../css/fonts.scss'

const AwardItem = ({ award }) => (
    <Award>
        {/* <UnstyledLink as={'a'}
            href={award.link_to_award ? award.link_to_award : ''}
            fonSize={45}
            target={award.link_to_award ? '_blank' : undefined}
            rel={award.link_to_award ? 'noopener noreferrer' : undefined}
        > */}
        <Header fontSize={31}>{award.title}</Header>
        <AwardDetail>
            <AwardHeader fontSize={20}>{award.detail}</AwardHeader>
            {award.link_info ? <AwardInfo fontSize={20}>{award.link_info}</AwardInfo> : ''}
        </AwardDetail>
        {/* </UnstyledLink> */}

    </Award>
)

class Awards extends Component {
    componentDidMount() {
        this.props.dispatch(loadPage('awards'))
        this.props.dispatch(loadAwards())
    }

    render() {
        const { page, awards } = this.props

        const meta = {
            title: page.meta_title || 'Inventure',
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50}>
                    <PageHeader marginbottom={90}>Awards</PageHeader>
                    {awards.map(({ year, awards }, i) =>
                        <FlexGrid key={i}>
                            <FlexGridColumn width={25}>{year}</FlexGridColumn>
                            <FlexGridColumn width={75}>
                                {awards.map((a, i) => <AwardItem key={i} award={a} />)}
                            </FlexGridColumn>
                        </FlexGrid>
                    )}
                </Container>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        awards: state.content.awards,
        page: state.content.page,
    }
}

const Award = styled.div`
    padding-bottom: 15px;
    &:not(:first-child) {
        padding-top: 15px;
        border-top: 1px solid ${colors.maroon};
    }

    @media only screen and ${devices.mobile} {
        font-size: 35px;
    }
`

const AwardDetail = styled.div`
    font-size: ${fonts.fontSmall};
    margin-top: 10px;

    @media ${minDevices.tablet} {
        margin-left: 90px;
    }
`

const AwardHeader = styled(Header)`
    @media only screen and ${devices.mobile} {
        font-size: 20px !important;
    }
`

const AwardInfo = styled(Header)`
    @media only screen and ${devices.mobile} {
        font-size: 20px !important;
    }
`

const PageHeader = styled(Header)`
    @media only screen and ${devices.mobile} {
        margin-top: 80px !important;
    }
`

const connectedAwards = connect(mapStateToProps)(Awards)
export { connectedAwards as Awards }
