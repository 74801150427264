import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, SVGLogo, devices, Image, Header, } from '../Common'
import { SimpleCarousel, LightBoxModal } from '../Content'
import _ from 'lodash'
import { loadPage, loadProjectList, loadAnimations, setLogoVisibility } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import styled from 'styled-components'
import colors from '../../css/colors.scss'
import { HomepageWord } from './HomepageWord'
import scrollToComponent from 'react-scroll-to-component'
import Lottie from 'react-lottie'
import VisibilitySensor from 'react-visibility-sensor';
import fonts from '../../css/fonts.scss'

class Home extends Component {
    state = {
        showLightBoxModal: false,
        mounted: false,
        arrowStopped: true,
    }

    componentDidMount() {
        const { dispatch, page } = this.props
        const mobile = window.innerWidth < 480

        if (page.slug !== 'index') dispatch(loadPage('index'))
        dispatch(loadProjectList('featured'))
        dispatch(loadAnimations())
        this.setState({ mounted: true })
        if (mobile) {
            setTimeout(() => {
                this.setState({ arrowStopped: false })
            }, 5200)
        }
    }


    componentWillUnmount() {
        this.props.dispatch(setLogoVisibility(true))
    }

    openModal = () => this.setState({ showLightBoxModal: true })

    closeModal = () => this.setState({ showLightBoxModal: false })

    onChange = (isVisible) => {
        const { dispatch } = this.props
        dispatch(setLogoVisibility(!isVisible))
    }

    render() {
        const { page, projects, dimensions, animations, } = this.props
        const meta = {
            title: page.meta_title || 'Inventure',
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        const featuredProjects = projects.map(project => ({
            image: project.hero_image,
            image_caption: project.name,
            linked_page: `/work/project/${project.slug}`,
        }))

        const homepageWords = _.map(page.list_of_words, w => ({
            text: w.word,
            animation: w.animation.animation_json,
        }))

        const mobile = window.innerWidth < 480
        const scrollDefaults = {
            offset: mobile ? 0 : 515,
            ease: 'out-cube',
        }

        const height = mobile ? 50 : 140

        const arrowAnimation = animations.find(({ name }) => name === 'Arrow')

        const defaultOptions = (animationData) => ({
            loop: false,
            autoplay: false,
            animationData: JSON.parse(animationData),
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        })

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />

                <Container margintop={mobile ? 0 : 50} marginbottom={50} id='container'>
                    <VisibilitySensor onChange={this.onChange} resizeCheck={true} partialVisibility={true} scrollCheck={true} >
                        <IntroText id="introText">
                            <div>We are a group of{homepageWords.map((w, i) => <HomepageWord key={w.text} index={i} word={w} mobile={mobile} />)}</div>
                            {/* <br /> */}
                            <WeAre
                                onMouseEnter={() => !mobile && this.setState({ arrowStopped: false })}
                                onMouseLeave={() => !mobile && this.setState({ arrowStopped: true })}
                                mounted={this.state.mounted}>We are <SVGLogo
                                    width={mobile ? '134' : '213.428'}
                                    height={mobile ? '29' : '45.322'}
                                    viewBox={'0 0 210.428 44.9'}
                                    className='home-logo'
                                />.
                            {arrowAnimation ?
                                    <Lottie
                                        options={defaultOptions(arrowAnimation.animation_json)}
                                        height={height}
                                        width={mobile ? 99 : height * 2}
                                        speed={0.7}
                                        isStopped={this.state.arrowStopped}
                                        isClickToPauseDisabled={true}
                                        style={{ display: 'inline-block' }}
                                    /> : ''}
                            </WeAre>
                            <ScrollButton role='button' aria-label='Watch video' onClick={() => scrollToComponent(this.video, scrollDefaults)}>
                                Watch Video
                            <ImageIcon src={'/static/images/down-arrow.svg'} height={28} alt='Arrow pointing down to video' />
                            </ScrollButton>
                        </IntroText>
                    </VisibilitySensor>
                </Container>
                <React.Fragment>
                    {page.page_blocks && page.page_blocks.filter(p => p.block_format === 'CAROUSEL').map((p, i) =>
                        <SimpleCarousel
                            key={i}
                            ref={(carousel) => { this.video = carousel }}
                            showIndicator={false}
                            slides={p.carousel}
                            dimensions={dimensions}
                            videoPreview={true}
                            refProp={this.featured}
                        />
                    )}
                    <Container margintop={50} marginbottom={50}>
                        <div>
                            <FeaturedHeader>
                                <Header marginbottom={50}>Featured Projects</Header>
                                {/* {!mobile && <ImageIcon role='img' aria-label='Full screen' src={'/static/images/full-screen.svg'} height={31} onClick={this.openModal} />} */}
                            </FeaturedHeader>
                            <SimpleCarousel ref={(carousel) => { this.featured = carousel }} showIndicator={true} slides={featuredProjects} dimensions={dimensions} />
                        </div>
                    </Container>
                    {this.state.showLightBoxModal ? ( // do not mount component unless active, 'dislpay: none' does not prevent the scroll effect
                        <LightBoxModal
                            slides={featuredProjects}
                            showLightBoxModal={this.state.showLightBoxModal}
                            closeModal={this.closeModal}
                        />
                    ) : ''}
                </React.Fragment>
            </React.Fragment>
        )
    }
}

const ImageIcon = styled(Image)`
    cursor: pointer;
`

const WeAre = styled.div`
    transition: opacity 0.5s ease-in;
    opacity: ${({ mounted }) => mounted ? 1 : 0};
    transition-delay: 4800ms;
    position: relative;

    .home-logo {
        margin-left: 6px;
    }
`

const IntroText = styled.div`
    font-size: ${fonts.fontLarge};
    height: 100vh;
    position: relative;

    @media ${devices.mobile} {
        font-size: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    color: ${colors.maroon};
    fill: ${colors.maroon};
    svg {
        vertical-align: bottom;
        color: ${colors.maroon};
        fill: ${colors.maroon};
    }
`

const FeaturedHeader = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`

const ScrollButton = styled.div`
    font-size: ${fonts.fontSmall};
    position: absolute;
    bottom: 100px;
    right: 0;
    cursor: pointer;

    display: flex;
    align-items: center;

    ${Image} {
        margin-left: 10px;
    }
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        projects: state.project.filteredList,
        dimensions: state.content.dimensions,
        animations: state.content.animations,
        logo: state.content.logoHidden,
    }
}

const connectedHome = connect(mapStateToProps)(Home)
export { connectedHome as Home }
