import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { loadOfficeList, loadPage } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import {
    Header,
    Container,
    UnstyledLink,
    Image,
    devices,
    minDevices,
} from '../Common'
import { SocialIcons } from '../Global/SocialIcons'
import SlideDown from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { ContactForm } from '../Forms/ContactForm'
import fonts from '../../css/fonts.scss'

const isMobile = window.innerWidth < 480

const formatPhone = (number) => `${number.slice(2, 5)}.${number.slice(5, 8)}.${number.slice(8, 12)}`

const Office = ({ office }) => (
    <OfficeInfo>
        <div dangerouslySetInnerHTML={{ __html: office.address }} />
        <UnstyledLink fontSize={isMobile ? 28 : 31} as={'a'} href={`tel:${office.phone_number}`}>
            {formatPhone(office.phone_number)}
        </UnstyledLink>
        <UnstyledLink fontSize={isMobile ? 28 : 31} as={'a'} href={`mailto:${office.email}`} target='_blank'>
            {office.email}
        </UnstyledLink>
    </OfficeInfo>
)

const placeIDs = [
    'ChIJV3chov3AQIYR30h_S1EOG-Y', // Houston
    'ChIJQ6hB-FU7TIYRBd_5HAjL2q8', // Dallas
    'ChIJAQ9sc4nttocRvPYxjz2nnRs', // Tulsa
    'ChIJPZDrEzLsZIgRoNrpodC5P30', // Nashville
]

const getMapURL = (location, i) => `https://www.google.com/maps/search/?api=1&query=inventure+design+${location}&place_id_query=${placeIDs[i]}`

class Contact extends Component {
    state = {
        openLocation: 0,
        address: '3118+Richmod+Ave.+Suite+200+Houston,+Texas+77098',
        name: 'Houston'
    }

    componentDidMount() {
        this.props.dispatch(loadPage('contact'))
        this.props.dispatch(loadOfficeList())
    }

    setOpenLocation = (openLocation, office) => {
        const address = office.address.replace(/<br>/g, ' ').replace(/ /g, '+')
        this.setState({ openLocation, address, name: office.name })
    }


    render() {
        const { page, officeList } = this.props
        const { openLocation } = this.state
        const meta = {
            title: page.meta_title || 'Inventure',
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <ContactContainer margintop={50} marginbottom={10}>
                    <Column width={50}>
                        {officeList.map((office, i) =>
                            <React.Fragment key={i}>
                                <OfficeName
                                    marginbottom={10}
                                    margintop={i > 0 && i - 1 === openLocation ? 40 : 0}
                                    fontSize={20}
                                    onClick={() => this.setOpenLocation(i, office)}
                                >
                                    {office.name}
                                    {isMobile && i === openLocation ? (
                                        <React.Fragment>
                                            &nbsp;| <UnstyledLink fontSize={20} as={'a'} active={true} href={getMapURL(office.name, i)} target='_blank' rel='noopener noreferrer'>
                                                View on Map
                                            </UnstyledLink>
                                        </React.Fragment>
                                    ) : ''}
                                </OfficeName>
                                <SlideDown className={'my-dropdown-slidedown'}>
                                    {(i === openLocation) && <Office office={office} />}
                                </SlideDown>
                            </React.Fragment>
                        )}
                    </Column>
                    <Column width={50}>
                        <SocialHeader>
                            <Header marginbottom={20} marginright={15} fontSize={20}>Follow</Header>
                            <Image src={'/static/images/follow-arrow.svg'} alt='Arrow pointing to follow' height={35} />
                        </SocialHeader>
                        <SocialIcons
                            page={'outline'}
                            height={isMobile ? 35 : 50}
                            margin={isMobile ? 25 : 45} />
                    </Column>
                </ContactContainer>
                <ContactContainer margintop={10}>
                    <Map
                        role='img'
                        title={`Map showing where Inventure is located in ${this.state.name}`}
                        address={this.state.address}
                        width={window.innerWidth - 600}
                        height={Math.round((window.innerWidth - 600) / (1168 / 787))}
                    >
                        {/*
                        // TODO: we need lat/lng for each office as center if we want dynamic maps
                        <iframe src={ [...mapURL, `center=${this.state.address}`, `size=${window.innerWidth - 600}x${Math.round((window.innerWidth - 600) / (1168 / 787))}`, `markers=scale:4|icon:http://inventure.mouthwateringmedia.com/static/images/map_pin.png|${this.state.address}`].join('&') } />
                    */}
                    </Map>
                </ContactContainer>
                <Container margintop={50}>
                    <Header marginbottom={20}>Contact</Header>
                    <ContactForm />
                </Container>
            </React.Fragment>
        )
    }
}

const ContactContainer = styled(Container)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media ${devices.mobile} {
        flex-direction: column;
        margin-top: ${props => props.margintop * 2 || 0}px;
    }
`
const SocialHeader = styled.div`
    display: flex;
`

const Map = styled.div`
    ${({ address, width, height }) => `
        background: center / cover no-repeat url(${[
            ...mapURL,
            `center=${address}`,
            `size=${width}x${height}`,
            `markers=scale:4|icon:http://inventure.mouthwateringmedia.com/static/images/map_pin.png|${address}`
        ].join('&')});
    `}
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    margin-bottom: 80px;
`

const OfficeName = styled(Header)`
    cursor: pointer;
`

const OfficeInfo = styled.div`
    font-size: 31px;
    /* margin-bottom: 40px; */
    display: flex;
    flex-direction: column;

    @media ${devices.mobile} {
        font-size: ${fonts.fontSmall};
    }
`

const Column = styled.div`
    @media ${minDevices.tablet} {
        width: calc(${props => props.width}% - 15px);
    }

    @media ${devices.mobile} {
        margin-bottom: 40px;
    }
    /* padding: 15px 0; */

    @media ${minDevices.desktop} {
        height: 525px;
    }
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        officeList: state.team.officeList,
    }
}

const connectedContact = connect(mapStateToProps)(Contact)
export { connectedContact as Contact }


// const mapJSON = [
//   {
//     "elementType": "labels",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "administrative",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "administrative",
//     "elementType": "geometry",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.man_made",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "landscape.natural",
//     "stylers": [
//       {
//         "color": "#EEECE5"
//       }
//     ]
//   },
//   {
//     "featureType": "poi",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#BFBEB1"
//       }
//     ]
//   },
//   {
//     "featureType": "road",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "color": "#BFBEB1"
//       }
//     ]
//   },
//   {
//     "featureType": "road",
//     "elementType": "labels.icon",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "road.arterial",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       {
//         "color": "#7c7c7c"
//       },
//       {
//         "visibility": "on"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "color": "#BFBEB1"
//       }
//     ]
//   },
//   {
//     "featureType": "road.highway",
//     "elementType": "geometry.stroke",
//     "stylers": [
//       {
//         "color": "#BFBEB1"
//       },
//       {
//         "weight": 3.5
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "labels",
//     "stylers": [
//       {
//         "visibility": "on"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "labels.text",
//     "stylers": [
//       {
//         "color": "#7c7c7c"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "labels.text.fill",
//     "stylers": [
//       {
//         "color": "#7c7c7c"
//       }
//     ]
//   },
//   {
//     "featureType": "road.local",
//     "elementType": "labels.text.stroke",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "transit",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   },
//   {
//     "featureType": "water",
//     "elementType": "geometry.fill",
//     "stylers": [
//       {
//         "visibility": "off"
//       }
//     ]
//   }
// ]

const background = 'EEECE5'
const roads = 'BFBEB1'
const label = '62615A'
const mapURL = [
    'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCX70vOnZYfYxzz7QHjCG1xh2T_LKV0rvs',
    // 'https://www.google.com/maps/embed/v1/view?key=AIzaSyCX70vOnZYfYxzz7QHjCG1xh2T_LKV0rvs',
    'zoom=15',
    'format=png',
    'maptype=roadmap',
    'scale=2',
    'style=element:labels%7Cvisibility:off',
    'style=feature:administrative%7Cvisibility:off',
    'style=feature:administrative%7Celement:geometry%7Cvisibility:off',
    'style=feature:landscape.man_made%7Cvisibility:off',
    `style=feature:landscape.natural%7Ccolor:0x${background}`,
    'style=feature:poi%7Cvisibility:off',
    `style=feature:road%7Celement:geometry.fill%7Ccolor:0x${roads}`,
    `style=feature:road%7Celement:geometry.stroke%7Ccolor:0x${roads}`,
    'style=feature:road%7Celement:labels.icon%7Cvisibility:off',
    `style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x${label}%7Cvisibility:on`,
    `style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x${label}%7Cvisibility:on`,
    `style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0x${roads}`,
    `style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0x${roads}%7Cweight:3.5`,
    'style=feature:transit%7Cvisibility:off',
    'style=feature:water%7Celement:geometry.fill%7Cvisibility:off',
]
// const mapURL = [
//     'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCX70vOnZYfYxzz7QHjCG1xh2T_LKV0rvs',
//     // 'center=29.734735091682342,-95.42787957841871',
//     // 'size=480x360',
//     'zoom=15',
//     'format=png',
//     'maptype=roadmap',
//     'scale=2',
//     'style=element:labels%7Cvisibility:off',
//     'style=feature:administrative%7Cvisibility:off',
//     'style=feature:administrative%7Celement:geometry%7Cvisibility:off',
//     'style=feature:landscape.man_made%7Cvisibility:off',
//     'style=feature:landscape.natural%7Ccolor:0xc6bddd',
//     'style=feature:poi%7Cvisibility:off',
//     'style=feature:road%7Celement:geometry.fill%7Ccolor:0xa899e2',
//     'style=feature:road%7Celement:geometry.stroke%7Ccolor:0xa899e2',
//     'style=feature:road%7Celement:labels.icon%7Cvisibility:off',
//     'style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x6b0049%7Cvisibility:on',
//     'style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x6b0049%7Cvisibility:on',
//     'style=feature:road.highway%7Celement:geometry.fill%7Ccolor:0xa899e2',
//     'style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xa899e2%7Cweight:3.5',
//     'style=feature:transit%7Cvisibility:off',
//     'style=feature:water%7Celement:geometry.fill%7Cvisibility:off',
// ]
