import { createConstants } from '../_helpers/utils'

export const teamTypes = createConstants(
    'TEAM_LIST_REQUEST',
    'TEAM_LIST_SUCCESS',
    'TEAM_PAGE_REQUEST',
    'TEAM_PAGE_SUCCESS',
    'OFFICE_LIST_REQUEST',
    'OFFICE_LIST_SUCCESS',
    'JOB_LIST_REQUEST',
    'JOB_LIST_SUCCESS',
    'JOB_PAGE_REQUEST',
    'JOB_PAGE_SUCCESS',
    'BENEFIT_LIST_REQUEST',
    'BENEFIT_LIST_SUCCESS',
    'CLEAR_TEAM_LIST',
    'CLEAR_TEAM',
    'CLEAR_JOB_LIST',
    'CLEAR_JOB',
    'FILTER_PEOPLE',
    'CHANGE_PEOPLE_LAYOUT',
    'CLEAR_PERSON',
    'GENERAL_APP_REQUEST',
    'GENERAL_APP_SUCCESS',
)
