import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loadPage } from '../../_actions'
import DocumentMeta from 'react-document-meta'
import {
    ArticleWrap,
    Container,
    Block,
    Separator,
    devices,
} from '../Common'
import { PageBlock } from '../Content'
import styled from 'styled-components'

class StudioOverview extends Component {
    state = {
        width: 600,
    }

    container = React.createRef()

    componentDidMount() {
        this.updateWidth()
        window.addEventListener('resize', this.updateWidth.bind(this))
        this.props.dispatch(loadPage('studio'))
    }

    // componentDidUpdate() {
    //     this.updateWidth()
    // }

    updateWidth = () => {
        if (this.container.current) this.setState({ width: this.container.current.offsetWidth })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWidth)
    }

    render() {
        const { page, dimensions } = this.props
        const meta = {
            title: `Inventure | ${page.meta_title || page.title}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }
        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50} ref={this.container}>
                    {page.page_blocks ? (
                        <StudioWrap>
                            {page.page_blocks.filter(block => block.type === 'LEFT').map((b, i) =>
                                <React.Fragment key={i.toString()}>
                                    <Block margintop={i > 0 ? 15 : 0} marginbottom={i < 3 ? 30 : 0}>
                                        <PageBlock pageBlock={b} url={page.url} dimensions={dimensions} width={this.state.width} />
                                    </Block>
                                    {i < page.page_blocks.length - 3 && <Separator thickness={0.5} />}
                                </React.Fragment>
                            )}
                        </StudioWrap>
                    ) : ''}
                </Container>
            </React.Fragment>
        )
    }
}

const StudioWrap = styled(ArticleWrap)`
    @media ${devices.mobile} {
        margin-top: 75px;
    }
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        dimensions: state.content.dimensions,
    }
}

const connectedStudioView = connect(mapStateToProps)(StudioOverview)
export { connectedStudioView as StudioOverview }
