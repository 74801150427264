import React, { Component } from 'react'
import ReactPlayer from 'react-player'
// import styled from 'styled-components'
// import colors from '../../../css/colors.scss'
import { Modal, CloseContainer, CloseVideo } from '../../Common'

class VideoModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: this.props.showModal,
            url: this.props.url,
            closeModal: this.props.closeModal,
            playing: true,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            showModal: nextProps.showModal,
            url: nextProps.url,
        })
    }

    render() {
        return (
            <Modal show={this.state.showModal}>
                <CloseContainer>
                    <CloseVideo onClick={this.state.closeModal}>&#xd7;</CloseVideo>
                </CloseContainer>
                <ReactPlayer
                    url={this.state.url}
                    playing={this.state.showModal}
                    height="100vh"
                    width="100vw"
                    volume={this.props.mobile ? 0 : 1}
                    muted={this.props.mobile}
                    playsinline={this.props.mobile}
                // controls
                />
            </Modal>
        )
    }
}

export { VideoModal }
