import axios from 'axios'

export const Pages = {
    get: (slug, url_category, url_subcategory) => {
        const category = url_category ? `?category=${url_category}` : ''
        const subcategory = url_subcategory ? `&subcategory=${url_subcategory}` : ''
        return axios.get(`/api/page/${slug}/${category}${subcategory}`)
    }
}

export const Menu = {
    getList: () => axios.get('/api/menu/')
}

export const MailChimp = {
    post: (email) => axios.post('/mailchimp-subscribe/', { email }),
}

export const Award = {
    getList: () => axios.get('/api/award/')
}

export const Animation = {
    getList: () => axios.get('/api/animation/'),
    get: (name) => axios.get(`/api/animation/${name}`),
}

export const TermsAndCopyright = {
    get: () => axios.get('/api/terms-and-copyright/1/')
}
