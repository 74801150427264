import React from 'react'
import styled from 'styled-components'
import { devices, minDevices, margins, } from './'
import colors from '../../css/colors.scss'
import fonts from '../../css/fonts.scss'

const Header = ({ as = 'div', ...rest }) => {
    return React.createElement(styled(as).attrs(() => rest)`
        /* font-weight: 700; */
        max-width: ${props => props.unsetmaxwidth ? 'unset' : '690px'};
        line-height: 1.3;
        font-size: ${({ fontSize = 31 }) => fontSize}px;
        text-decoration: none;
        ${margins}
        @media ${devices.mobile} {
           font-size: ${fonts.fontSmall};
        }
    `)
}

const SVGLogo = ({ width = 210.428, height = 37.322, viewBox = '0 0 210.428 37.322' }) => (
    <svg xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox={viewBox}
        preserveAspectRatio='xMinYMin meet'
        className='home-logo'
    >
        <g transform='translate(-547.021 386.02)'>
            <path className='a' d='M547.021-386.02h3.532v36.9h-3.532Z' />
            <path className='a' d='M566.635-368.286h3.321v5.536c1.37-4.007,4.744-5.957,8.961-5.957,6.009,0,9.911,4.27,9.911,10.859v16.13h-3.321v-15.708c0-4.955-2.794-8.224-7.063-8.224-4.956,0-8.487,4.007-8.487,9.647v14.285h-3.321Z' transform='translate(-8.386 -7.402)' />
            <path className='a' d='M611.847-367.971h3.479l8.118,22.457,8.118-22.457h3.532L625.342-341.4H621.6Z' transform='translate(-27.717 -7.717)' />
            <path className='a' d='M681.178-350.1c-.844,5.324-5.43,8.8-12.019,8.8-7.538,0-13.02-6.009-13.02-13.917,0-8.065,5.06-13.495,12.546-13.495,7.275,0,12.177,4.955,12.177,12.282a17.164,17.164,0,0,1-.105,1.74H659.512c.211,5.957,4.323,10.332,9.647,10.332,4.692,0,7.644-2.109,8.54-5.746Zm-21.455-7.644h17.66c-.369-4.9-3.637-7.907-8.7-7.907C663.835-365.65,660.567-362.7,659.723-357.742Z' transform='translate(-46.654 -7.402)' />
            <path className='a' d='M710.007-368.286h3.321v5.536c1.37-4.007,4.744-5.957,8.961-5.957,6.01,0,9.911,4.27,9.911,10.859v16.13h-3.321v-15.708c0-4.955-2.794-8.224-7.063-8.224-4.955,0-8.487,4.007-8.487,9.647v14.285h-3.321Z' transform='translate(-69.686 -7.402)' />
            <path className='a' d='M763.774-383.165v8.7h8.434v3.058h-8.434v15.55c0,3.057,1.74,4.9,4.428,4.9h4.006v3.057h-3.953c-4.8,0-7.8-3.057-7.8-7.96v-15.55H754.76v-3.058h5.693v-8.7Z' transform='translate(-88.82 -1.221)' />
            <path className='a' d='M815.26-341.4H811.94v-5.535c-1.371,4.007-4.744,5.957-8.962,5.957-6.009,0-9.91-4.27-9.91-10.859v-16.131h3.321v15.71c0,4.955,2.794,8.223,7.063,8.223,4.956,0,8.488-4.006,8.488-9.647v-14.286h3.321Z' transform='translate(-105.199 -7.717)' />
            <path className='a' d='M855.427-364.913c-5.851,0-7.38,5.377-7.38,12.177V-341.4h-3.321v-26.568h3.321v5.641c1.055-3.69,3.269-5.641,7.38-5.641h3.48v3.058Z' transform='translate(-127.286 -7.717)' />
            <path className='a' d='M895.916-350.1c-.844,5.324-5.43,8.8-12.019,8.8-7.538,0-13.021-6.009-13.021-13.917,0-8.065,5.06-13.495,12.546-13.495,7.275,0,12.177,4.955,12.177,12.282a17.145,17.145,0,0,1-.105,1.74H874.25c.211,5.957,4.323,10.332,9.647,10.332,4.692,0,7.643-2.109,8.54-5.746Zm-21.455-7.644h17.66c-.37-4.9-3.638-7.907-8.7-7.907C878.573-365.65,875.3-362.7,874.461-357.742Z' transform='translate(-138.467 -7.402)' />
        </g>
    </svg>
)

const UpperHeader = styled.h2`
    font-size: ${({ fontSize = 16 }) => fontSize}px;
    color: ${({ color = 'inherit' }) => color};
    font-weight: 200;
    /* letter-spacing: 2px; */
    ${margins}
    @media ${devices.mobile} {
       font-size: 16px;
    }
`

export const SmallCaps = styled.h2`
    font-size: 12px;
    font-family: 'VictorSerifMedium';
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: ${({ marginbottom = 5 }) => marginbottom}px;
`

const H3Header = styled(Header).attrs({ as: 'h3' })`
    font-size: ${({ fontSize = 25 }) => fontSize}px;
    margin-top: ${({ margintop = 10 }) => margintop}px;
    margin-bottom: ${({ marginbottom = 5 }) => marginbottom}px;
    line-height: 1.05;
    @media ${devices.mobile} {
       font-size: 22px;
    }
`

const Italic = styled(Header)`
    && {
        font-size: ${({ fontSize = 20 }) => fontSize}px;
        font-style: ${({ regular }) => regular ? 'normal' : 'italic'};
        color: ${({ color = 'inherit' }) => color};
        font-weight: 500;
        line-height: 1.1;
        @media ${devices.mobile} {
           font-size: 18px;
           padding-top: 7px;
        }
    }
`

const UnstyledLink = ({ as = 'a', ...rest }) => {
    return React.createElement(styled(as).attrs(() => rest)`
        font-size: ${({ fontSize = 20 }) => fontSize}px;
        cursor: pointer;
        /* color: inherit; */
        text-decoration: none;
        /* &:hover, &:focus, &:active, &:visited {
            color: inherit;
        } */
        ${({ active }) => active ? `color: ${colors.purple} !important;` : ''}
        ${({ marginbottom }) => marginbottom ? `margin-bottom: ${marginbottom}px;` : ''}
    `)
}

const BackButton = styled(UnstyledLink).attrs({ fontSize: 24 })`
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    padding-top: 50px;

    @media ${minDevices.tablet} {
        display: none;
    }
`

const ItalicLink = styled(Italic.withComponent('a'))`
    cursor: pointer;
    text-decoration: none;
    &:hover, &:focus, &:active {
        color: inherit !important;
    }
`


export {
    UpperHeader,
    H3Header,
    Italic,
    ItalicLink,
    Header,
    UnstyledLink,
    margins,
    SVGLogo,
    BackButton,
}
