import { createConstants } from '../_helpers/utils'

export const contentTypes = createConstants(
    'PAGE_DATA_REQUEST',
    'PAGE_DATA_SUCCESS',
    'PAGE_DATA_FAILURE',
    'MENU_LINKS_REQUEST',
    'MENU_LINKS_SUCCESS',
    'AWARD_LIST_REQUEST',
    'AWARD_LIST_SUCCESS',
    'SUBSCRIBE_REQUEST',
    'SUBSCRIBE_SUCCESS',
    'SUBSCRIBE_FAILURE',
    'TOGGLE_MENU',
    'UPDATE_MENU_COLOR',
    'RESET_MENU_COLOR',
    'RESIZE',
    'ANIMATION_LIST_REQUEST',
    'ANIMATION_LIST_SUCCESS',
    'ANIMATION_REQUEST',
    'ANIMATION_SUCCESS',
    'TC_REQUEST',
    'TC_SUCCESS',
    'LOGO_SHOW',
)
