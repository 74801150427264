import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { loadTeamPage, clearPerson, setCardHeight } from '../../_actions'
import { UnstyledLink, Container, devices, Image, BackButton, } from '../Common'
import colors from '../../css/colors.scss'
import { Link } from 'react-router-dom'
import fonts from '../../css/fonts.scss'

class PersonDetail extends Component {

    constructor(props) {
        super(props)
        this.image = React.createRef()
    }

    componentDidMount() {
        const { match, dispatch } = this.props
        dispatch(clearPerson())
        dispatch(loadTeamPage(match.params.person))
        this.updateHeight()
        window.addEventListener('resize', this.updateHeight.bind(this));
    }

    formatPhone = (number) => {
        if (number) {
            return `${number.slice(2, 5)}.${number.slice(5, 8)}.${number.slice(8, 12)}`
        }
    }

    updateHeight = () => {
        const { dispatch } = this.props
        const ratio = 5 / 7,
            width = this.image.current.offsetWidth
        const height = Math.round(width / ratio)
        dispatch(setCardHeight(height))
    }

    render() {
        const { person, cardHeight } = this.props
        return (
            <PersonContainer margintop={50}>
                <BackButton as={Link} to={'/team'}>
                    Back
                    <Image src={'/static/images/back-icon.svg'} height={25} />
                </BackButton>
                <Intro>
                    <ProfileImage ref={this.image} height={cardHeight} src={person.profile_picture} role='img' aria-label={`A picture of ${person.name}`} data-clippath={'alt1'} />
                    <ContactInfo>
                        <Name>
                            {person.name}
                            <br />
                            {person.title}
                        </Name>
                        <TelephoneNumber as={'a'} href={`tel:${person.phone_number}`}>{this.formatPhone(person.phone_number)}</TelephoneNumber>
                        <Email as={'a'} href={`mailto:${person.email}`}>{person.email}</Email>
                        {person.linkedin_link ? <LinkedIn as={'a'} href={person.linkedin_link}>LinkedIn</LinkedIn> : ''}
                    </ContactInfo>
                </Intro>
                {person.quote && <Quote>&#8220;{person.quote}&#8221;</Quote>}
                <Body dangerouslySetInnerHTML={{ __html: person.body }} />
            </PersonContainer>
        )
    }
}

const PersonContainer = styled(Container)`
    /* @media only screen and ${devices.tablet} {
        margin-top: 0;
    } */

    @media only screen and ${devices.desktop} {
        margin-right: 20px;
    }
`

const ProfileImage = styled.div`
    width: 100%;
    background: center / cover no-repeat url(${({ src }) => src});

    max-width: 567px;
    height: ${({ height }) => height}px;

    /* @media only screen and ${devices.desktop} {
        height: 468px;
        max-width: 335px;
    } */
`

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: 100%;

    @media only screen and ${devices.tablet} {
        margin-left: 0;
    }
    @media only screen and ${devices.desktop} {
        margin-right: 20px;
    }
`

const Intro = styled.div`
    display: flex;
    margin-bottom: 30px;

    @media only screen and ${devices.tablet} {
        flex-direction: column;
        flex-wrap: wrap;
    }
`

const Name = styled.div`
    font-size: ${fonts.fontMedium};
    margin-bottom: 20px;
    padding-bottom: 30px;
    width: 100%;
    border-bottom: 1px solid ${colors.maroon};

    @media only screen and ${devices.mobile} {
        font-size: ${fonts.fontSmall};
        margin-top: 30px;
        padding-bottom: 20px;
    }
`

const TelephoneNumber = styled(UnstyledLink)`
    text-decoration: none;
    @media only screen and ${devices.mobile} {
        font-size: 20px;
    }
`

const Email = styled(UnstyledLink)`
    text-decoration: none;
    @media only screen and ${devices.mobile} {
        font-size: 20px;
    }
`

const LinkedIn = styled(UnstyledLink)`
    text-decoration: none;
    @media only screen and ${devices.mobile} {
        font-size: 20px;
    }
`

const Quote = styled.div`
    /* margin: 30px 0 40px; */
    font-size: ${fonts.fontLarge};

    @media only screen and ${devices.mobile} {
        font-size: ${fonts.fontMedium};
    }
`

const Body = styled.div`
    margin-top: 40px;
    margin-bottom: 50px;

    @media only screen and ${devices.mobile} {
        font-size: 20px;
    }
`


function mapStateToProps(state) {
    return {
        person: state.team.page,
        cardHeight: state.project.cardHeight,
    }
}

const connectedPersonDetail = connect(mapStateToProps)(PersonDetail)
export { connectedPersonDetail as PersonDetail }
