import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { history } from '../../_helpers'
import styled from 'styled-components'
import { UnstyledLink, devices, ScrollCollision, SVGLogo, } from '../Common'
import { loadPage, setLogoVisibility } from '../../_actions'
import colors from '../../css/colors.scss'

const Nav = ({ menu, url, openNavItemId, that, openChildId, mobile }) => (
    <FixedNav>
        {menu.filter(link => !link.parent).map((link, i) =>
            <div key={`base${i}`}>
                {!mobile && url.includes(link.linked_page) && openNavItemId !== i && that.toggleNavItem(i)}
                <UnstyledLink
                    as={mobile ? 'div' : Link}
                    role={mobile ? 'button' : null}
                    to={link.linked_page}
                    onClick={() => that.toggleNavItem(i)}
                    fontSize={50}
                >
                    {link.title}
                </UnstyledLink>

                {(openNavItemId === i) &&
                    <List>
                        {link.children.map((l, j) =>
                            <SubmenuItem key={`main${j}`}>
                                {url.includes(l.linked_page) && (typeof openChildId === 'undefined') && that.toggleChildItem(l.children ? j : null)}
                                <UnstyledLink
                                    active={url === l.linked_page ? 1 : 0}
                                    as={Link}
                                    role={mobile ? 'button' : null}
                                    to={l.linked_page}
                                    onClick={() => {
                                        if (mobile) {
                                            if (!l.children) that.toggleMenu()
                                            // some projects only have the paarent category, so the menu needs to
                                            // close if they open the menu again, they can select sub categories
                                            if (l.linked_page !== window.location.pathname && l.children) that.toggleMenu()
                                        }
                                        that.toggleChildItem(l.children ? j : null)
                                    }}
                                >
                                    {l.title}
                                </UnstyledLink>
                                {(openChildId === j) &&
                                    <InnerList>
                                        {l.children && l.children.map((e, k) =>
                                            <SubmenuItem key={`inner${k}`}>
                                                <UnstyledLink
                                                    active={url === e.linked_page ? 1 : 0}
                                                    as={Link}
                                                    to={e.linked_page}
                                                    onClick={() => mobile ? that.toggleMenu() : ''}
                                                >
                                                    {e.title}
                                                </UnstyledLink>
                                            </SubmenuItem>
                                        )}
                                    </InnerList>
                                }
                            </SubmenuItem>
                        )}
                    </List>
                }
            </div>
        )}
    </FixedNav>
)

const MobileSVGLogo = () => (
    <SVGLogo width={134} height={23} />
)

class SideNav extends Component {
    state = {
        openNavItemId: undefined,
        openChildId: undefined,
        url: window.location.pathname,
        menuOpen: false,
    }

    componentDidMount() {
        const { page } = this.props.match.params
        const { dispatch } = this.props
        if (page && page.slug !== page) dispatch(loadPage(page))
        if (this.props.page.slug !== 'index') dispatch(setLogoVisibility(true))

        history.listen((location) => {
            this.setState({ url: location.pathname, openChildId: undefined })
            this.forceUpdate()
        });
    }

    toggleNavItem = (id) => this.setState({ openNavItemId: id })
    toggleChildItem = (id) => this.setState({ openChildId: id })
    toggleMenu = () => this.setState({ menuOpen: !this.state.menuOpen })
    closeMenu = () => this.setState({ openNavItemId: null, openChildId: null })

    render() {
        const { menu, dimensions, logo, page } = this.props
        const { openNavItemId, openChildId, url } = this.state
        return (
            <React.Fragment>
                {dimensions.width > 1240 ? (
                    <ColorChange>
                        <ScrollCollision className='menu' blocks={document.querySelectorAll('[data-clippath]')}>
                            <Head page={page.slug} visible={logo}>
                                <LogoWrap as={Link} to='/' onClick={this.closeMenu} aria-label='Home'>
                                    <SVGLogo />
                                </LogoWrap>
                            </Head>
                            <Nav
                                menu={menu}
                                url={url}
                                openNavItemId={openNavItemId}
                                that={this}
                                openChildId={openChildId}
                            />
                        </ScrollCollision>
                    </ColorChange>
                ) : (
                    <MobileNav>
                        <ScrollCollision className='logo' width='270px' blocks={document.querySelectorAll('[data-clippath]')}>
                            <Head page={page.slug} visible={logo}>
                                <LogoWrap as={Link} to='/' onClick={this.closeMenu} aria-label='Home'>
                                    <MobileSVGLogo />
                                </LogoWrap>
                            </Head>
                        </ScrollCollision>
                        <ScrollCollision className='menu-button' width='120px'>
                            <MenuButton role='button' onClick={this.toggleMenu}>Menu</MenuButton>
                        </ScrollCollision>
                        <MobileMenu open={this.state.menuOpen}>
                            <Head>
                                <LogoWrap as={Link} to='/' onClick={this.toggleMenu} aria-label='Home'>
                                    {dimensions.width > 1240 ? <SVGLogo /> : <MobileSVGLogo />}
                                </LogoWrap>
                            </Head>
                            <Nav
                                menu={menu}
                                url={url}
                                openNavItemId={openNavItemId}
                                that={this}
                                openChildId={openChildId}
                                mobile={true}
                            />
                            <BackButton role='button' onClick={this.toggleMenu}>Close</BackButton>
                        </MobileMenu>
                    </MobileNav>
                )}
            </React.Fragment>
        );
    }
}

const MenuButton = styled.span`
    line-height: 0.6;
    font-size: 39px;
    cursor: pointer;

    @media ${devices.mobile} {
        font-size: 30px;
    }
`

const BackButton = styled.div`
    line-height: 0.6;
    font-size: 50px;
    cursor: pointer;
    position: fixed;
    bottom: 50px;
    left: 20px;
`

const MobileNav = styled.div`
    width: 100vw;
    z-index: 10000;
`

const MobileMenu = styled.div`
    /* ${({ open }) => open ? '' : 'display: none;'} */
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: ${colors.maroon};

    transform: translateX(${({ open }) => open ? 0 : 100}%);
    transition: transform 0.5s ease-out;

    a, div {
        color: white !important;
    }

    svg {
        fill: white;
        position: fixed;
        top: 30px;
        left: 20px;
    }
`

const FixedNav = styled.div`
    margin-left: 50px;
    position: fixed;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    z-index: 3;
    overflow: hidden; // TODO: Fix scrolling menu on short screen

    div {
        a {
            transition: color 0.2s ease-in;
            @media (hover: hover) and (pointer: fine) {
                &:hover {
                    color: ${colors.purple};
                }
            }
        }
    }

    @media ${devices.desktop} {
        bottom: unset;
        top: 110px;
        margin-left: 20px;
    }
`

const List = styled.ul`
    margin-bottom: 30px;
`

const InnerList = styled.ul`
    margin-left: 50px;
`

const SubmenuItem = styled.li`
    margin-bottom: 1px;
    /* @media ${devices.mobile} {
        margin-bottom: 8px;
    } */
`

const Head = styled.header`
    background: transparent;
    /* position: absolute; */
    margin: 50px 0 0 50px;

    /* transform: translateX(${({ visible }) => visible ? 0 : -100}%); */
    opacity: ${({ visible }) => visible ? 1 : 0};
    pointer-events: ${({ visible }) => visible ? 'auto' : 'none'};
    transition: opacity 0.2s ease-in;

    @media ${devices.desktop} {
        margin: 0;
    }
`

const LogoWrap = styled.div`
    /* transform: translateX(${({ open }) => open ? 0 : 100}%);
    transition: transform 0.5s ease-out; */
`

const ColorChange = styled.div`
    height: 100vh;
    position: absolute;
    z-index: 10000;

    @media only screen and ${devices.desktop} {
        .menu, .alt-menu {
            display: none !important;
        }
    }
`

function mapStateToProps(state) {
    return {
        menu: state.content.menu,
        page: state.content.page,
        dimensions: state.content.dimensions,
        logo: state.content.logoHidden,
    }
}

const connectedSideNav = connect(mapStateToProps)(SideNav)
export { connectedSideNav as SideNav }
