import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form/immutable'
import { postGeneralApplicationForm } from '../../_actions'
import { Button, Container, Header } from '../Common'
import { FormField, validateRequired, BaseFields } from '../Forms/Forms'

class GeneralApplication extends Component {

    state = {
        buttonText: 'Submit',
        sent: false,
    }

    handleSubmit = (data) => {
        this.props.dispatch(postGeneralApplicationForm(data))
        this.setState({ buttonText: 'Sent', sent: true })
    }

    render() {
        const { handleSubmit } = this.props
        const { buttonText, sent } = this.state
        return (
            <Container margintop={50}>
                <Header fontSize={72}>General Application</Header>
                <form onSubmit={handleSubmit(this.handleSubmit)}>
                    <BaseFields />
                    <Field
                        name='location'
                        type='text'
                        component={FormField}
                        label='Location (City)'
                        required
                        validate={[validateRequired,]} />
                    <Field
                        name='linkedin'
                        type='url'
                        component={FormField}
                        label='LinkedIn Profile'
                        required
                        validate={[validateRequired,]} />
                    <Field
                        name='website'
                        type='url'
                        component={FormField}
                        label='Website / Portfolio'
                        required
                        validate={[validateRequired,]} />
                    <Field
                        name='position'
                        type='text'
                        component={FormField}
                        label='Position'
                        required
                        validate={[validateRequired,]} />
                    <Field
                        name='why_are_you_perfect'
                        component={FormField}
                        label='Why are you perfect for the job?'
                        required
                        validate={[validateRequired,]} />
                    {/* <Field
                        name='resume'
                        component={FormField}
                        label='Resume/CV'
                        type='file'
                        required
                        value={null}
                        validate={[validateRequired,]} /> */}
                    <Button type='submit' disabled={sent}>{buttonText}</Button>
                </form>
            </Container>
        )
    }
}

const connectedApplicationForm = reduxForm({ form: 'general-application' })(GeneralApplication)
export { connectedApplicationForm as GeneralApplication }