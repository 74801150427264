import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Header, } from '../Common'
import { loadTermsAndCopyright, loadPage } from '../../_actions'
import DocumentMeta from 'react-document-meta'

class Flatpage extends Component {
    componentDidMount() {
        const { dispatch, match } = this.props
        const { privacy } = match.params
        dispatch(loadPage(privacy))
        dispatch(loadTermsAndCopyright())
    }

    render() {
        const { page, tc } = this.props

        const meta = {
            title: page.meta_title || `Inventure | ${page.title}`,
            description: page.meta_description,
            canonical: page.meta_cannonical_url,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: page.meta_keywords
                }
            }
        }

        return (
            <React.Fragment>
                <DocumentMeta {...meta} />
                <Container margintop={50} marginbottom={50}>
                    <Header fontSize={48}>{page.title}</Header>
                    <div dangerouslySetInnerHTML={{ __html: page.slug === 'copyright' ? tc.copyright_body : tc.terms_body }} />
                </Container>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        page: state.content.page,
        tc: state.content.termsAndCopyright,
    }
}

const connectedFlatpage = connect(mapStateToProps)(Flatpage)
export { connectedFlatpage as Flatpage }
