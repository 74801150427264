import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../css/colors.scss'
import { devices } from '../Common'

const ListItem = ({ person, last, headerWidths }) => (
    <Item to={ `/team/${person.slug}` } margin={ last ? 50 : 0 }>
        <Column borderWidth={ last ? 0 : 1 } width={ headerWidths.header1 }>{ person.name }</Column>
        <Column borderWidth={ last ? 0 : 1 } width={ headerWidths.header2 }>{ person.title }</Column>
    </Item>
)

class PeopleList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            people: props.people,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            people: nextProps.people,
        })
    }

    render() {
        const headerWidths = {
            header1: 50,
            header2: 50,
        }
        return (
            <React.Fragment>
                <ListHeader>
                    <Header borderWidth='2' width={ headerWidths.header1 }>Name</Header>
                    <Header borderWidth='2' width={ headerWidths.header2 }>Title</Header>
                </ListHeader>
                <ListContainer>
                    {this.state.people.map((e, i) =>
                        <ListItem
                            key={ i }
                            last={ i === this.state.people.length - 1 }
                            person={ e }
                            headerWidths={ headerWidths }
                        />
                    )}
                </ListContainer>
            </React.Fragment>
        )
    }
}

const ListHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const Header = styled.div`
    width: calc(${props => props.width}% - 15px);
    border-bottom: ${props => props.borderWidth}px solid ${colors.maroon};
    padding: 15px 0;

    @media only screen and ${devices.mobile} {
        width: calc(${props => props.width}% - 10px);
    }
`

const ListContainer = styled.div`

`

const Item = styled(Link)`
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${props => props.margin}px;

    @media only screen and ${devices.mobile} {
        font-size: 20px;
    }
`

const Column = styled(Header)`

`


export { PeopleList }
